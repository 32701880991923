import React, { useEffect, useState } from 'react';
import { OUTLET_HEIGHT } from '../../config/theme';
import {
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  styled
} from '@mui/material';
import { NavigateNext, Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import ConfirmModal from '../../components/ConfirmModal';
import { useGetEmailConfigQuery, useSetEmailConfigMutation } from './apiSlice';
import TabEmpresa from './TabEmpresa';
import { useSelector } from 'react-redux';
import { selectEmpresa } from '../empresa/storeSlice';
import { useFormik } from 'formik';
import { EmailConfig, formConfigSchema } from './models';
import TabEmail from './TabEmail';
import TabSMTP from './TabSMTP';

const Inner = styled('div')(() => ({
  height: OUTLET_HEIGHT
}));

const TAB_SX = (theme: Theme, valueTab: number, index: number) => ({
  textTransform: 'none',
  fontSize: '0.8rem',
  fontWeight: valueTab === index ? 'bold' : 'inherit',
  textAlign: 'left',
  flex: 1,
  bgcolor:
    valueTab === index ? theme.palette.secondary.main : theme.palette.grey[100]
});

export default function ConfigEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const empresa = useSelector(selectEmpresa);

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: emailConfig, isLoading: isLoadingEmailConfig } =
    useGetEmailConfigQuery();
  const [setEmailConfig] = useSetEmailConfigMutation();

  const formik = useFormik<Partial<EmailConfig>>({
    validationSchema: formConfigSchema,
    initialValues: {} as EmailConfig,
    onSubmit: (values) => {
      const newValues: Record<string, any | null> = { ...values };
      newValues.updated_at = newValues.updatedAt;

      const emailConfig: EmailConfig = {
        id: Number(newValues.id),
        tipoConfiguracao: newValues.tipoConfiguracao,
        smtpServer: newValues.smtpServer,
        porta: Number(newValues.porta),
        isSecure: Boolean(newValues.isSecure),
        usuario: newValues.usuario,
        senha: newValues.senha,
        from: newValues.from,
        assunto: newValues.assunto,
        corpo: newValues.corpo,
        corpoView: newValues.corpoView,
        imagem: newValues.imagem,
        updated_at: newValues.updatedAt
      };
      // console.log(
      //   '🚀 ~ file: index.tsx:73 ~ ConfigEdit ~ emailConfig:',
      //   emailConfig
      // );

      setEmailConfig(emailConfig)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Configurações salvas com sucesso!', {
            variant: 'success'
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.data.message, {
            variant: 'error'
          });
        });
    }
  });

  useEffect(() => {
    if (emailConfig) {
      formik.setValues(emailConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailConfig]);

  return (
    <form>
      <Inner>
        <Box
          py={1}
          display="flex"
          alignItems={'center'}
          justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Configurações</Typography>
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <ConfirmModal
              title="Salvar configurações"
              description={`Confirmar novas configurações?`}
              action={() => formik.handleSubmit()}
              component={
                <Button
                  type="button"
                  startIcon={<Save />}
                  color="success"
                  variant="contained">
                  Salvar
                </Button>
              }
            />
          </Box>
        </Box>
        <Paper
          sx={(theme) => ({
            height: '78vh',
            padding: '10px 0'
          })}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ hidden: true }}
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
                height: 3
              },
              '& button.Mui-selected': {
                color: theme.palette.common.white!
              }
            })}>
            <Tab
              key={0}
              sx={(theme) => TAB_SX(theme, value, 0)}
              label="Empresa"
            />
            <Tab key={1} sx={(theme) => TAB_SX(theme, value, 1)} label="SMTP" />
            <Tab
              key={2}
              sx={(theme) => TAB_SX(theme, value, 2)}
              label="Email"
            />
          </Tabs>
          <TabEmpresa key={0} value={value} index={0} data={empresa} />
          <TabSMTP
            key={1}
            value={value}
            index={1}
            formik={formik}
            isLoading={isLoadingEmailConfig}
          />
          <TabEmail
            key={2}
            value={value}
            index={2}
            formik={formik}
            isLoading={isLoadingEmailConfig}
          />
        </Paper>
        {formik.errors && (
          <Paper
            sx={{
              color: 'error.main',
              fontSize: '0.8rem',
              textAlign: 'center',
              mt: 0.5
            }}>
            {Object.entries(formik.errors)
              .map(([key, val]: any) => `${key}: ${val}`)
              .join(', ')}
          </Paper>
        )}
      </Inner>
    </form>
  );
}
