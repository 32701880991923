/* eslint-disable prettier/prettier */
import { Box, Breadcrumbs, Button, styled, Typography } from '@mui/material';

import { Link } from 'react-router-dom';
import AppConfig from '../../../config/app';
import { OUTLET_HEIGHT } from '../../../config/theme';
import { useDesaprovarRequisicaoLoteMutation } from '../apiSlice';

import Table from '../table';
import { useGetSituacaosQuery } from '../../situacao/apiSlice';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useGetPrioridadesQuery } from '../../prioridade/apiSlice';
import { useGetSolicitantesQuery } from '../../solicitante/apiSlice';
import { useGetDepartamentosQuery } from '../../departamento/apiSlice';
import { Requisicao } from '../models';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import RightDrawer from '../../../components/RightDrawer';
import { Add, FilterAltOutlined, NavigateNext } from '@mui/icons-material';

import { useRequisicao } from '../context';
import SelectFilter from '../../../components/filters/selectFilter';
import PeriodFilter from '../../../components/filters/periodFilter';
import CustomSearch from '../../../components/CustomSearch';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../auth/authSlice';
import { EnumPermissao } from '../../auth/models';
import { useGetClassesQuery } from '../../classes/apiSlice';

const dataTipo = [
  {
    id: 'P',
    nome: 'Produto'
  },
  {
    id: 'S',
    nome: 'Serviço'
  }
];

const drawerWidth = 240;
const Inner = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: OUTLET_HEIGHT,
  flexGrow: 1,
  position: 'relative',
  zIndex: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: 60,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

export default function Requisicoes() {
  const {
    data,
    loading: isLoading,
    filterRequisicoes,
    filters,
    setFilters,
    searchRequisicoes,
    searchFor,
    setType
  } = useRequisicao();
  // const [findRequisicao, status] = useFindRequisicoesMutation();
  const [desaprovarRequisicaoLote] = useDesaprovarRequisicaoLoteMutation();

  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState<Requisicao[]>([]);

  // filtro situacoes
  const { data: dataSituacoes, isLoading: isLoadingSituacoes } =
    useGetSituacaosQuery(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {},
      {
        refetchOnMountOrArgChange: true
      }
    );

  // filtro prioridade
  const { data: dataPrioridade, isLoading: isLoadingPrioridade } =
    useGetPrioridadesQuery(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {},
      {
        refetchOnMountOrArgChange: true
      }
    );

  // filtro solicitante
  const { data: dataSolicitante, isLoading: isLoadingSolicitante } =
    useGetSolicitantesQuery(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {},
      {
        refetchOnMountOrArgChange: true
      }
    );

  // filtro destino
  const { data: dataDepartamento, isLoading: isLoadingDepartamento } =
    useGetDepartamentosQuery(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {},
      {
        refetchOnMountOrArgChange: true
      }
    );

  // filtro classes
  const { data: dataClasses, isLoading: isLoadingClasses } = useGetClassesQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlerFilter = (filter: Record<string, any>) => {
    // verifica se a data inicial é maior que a data final
    const oldFilters = filters;
    const [dt1, dt2] = filter.created_at ?? [null, null];
    const dtIni = dt1 ? new Date(dt1) : null;
    const dtFim = dt2 ? new Date(dt2) : null;
    if (dtIni && dtFim && dtIni > dtFim) {
      enqueueSnackbar('A data inicial não pode ser maior que a data final', {
        variant: 'warning'
      });
      setFilters(oldFilters);
      return;
    }
    setType('filter');
    const newfilters = { ...filters, ...filter, created_at: filter.created_at };
    setFilters(newfilters);
  };

  const handleSearch = (value: string) => {
    searchRequisicoes(value);
  };

  const [openDrawer, setOpenDrawer] = useLocalStorage(
    'requisicao-right-drawer',
    false
  );

  const user = useSelector(selectCurrentUser);
  function authorization(action: string) {
    return user.permissoes[EnumPermissao.REQUISICAO].includes(action);
  }

  async function handlerDesaprovar() {
    if (!authorization('a')) {
      enqueueSnackbar('Usuário não autorizado', {
        variant: 'error'
      });
      return;
    }
    if (selectedRows.length === 0) {
      enqueueSnackbar('Selecione pelo menos uma requisição', {
        variant: 'warning'
      });
      return;
    }

    const selecteds = selectedRows.map((selected) => ({
      id: selected.id ?? 0,
      updated_at: selected.updatedAt ?? new Date()
    }));

    await desaprovarRequisicaoLote(selecteds);
    filterRequisicoes();
    // for await (const selected of selectedRows) {
    //   await managerRequisicao({
    //     id: selected.id!.toString(),
    //     updated_at: selected.updatedAt ?? new Date(),
    //     comando: 'aprovar'
    //   });
    //   filterRequisicoes();
    // }
  }

  async function toFilter() {
    filterRequisicoes();
  }

  const initialLoading = useCallback(() => {
    return filterRequisicoes();
  }, [filterRequisicoes]);

  useEffect(() => {
    initialLoading();
  }, [initialLoading]);

  return (
    <Inner open={openDrawer}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }} gap={1}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography fontWeight={'bold'}>Requisições</Typography>
        </Breadcrumbs>

        <Box display={'flex'} gap={2}>
          <CustomSearch value={searchFor} handleSearch={handleSearch} />

          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={AppConfig.routes.cadRequisicaoInsert}
            aria-label="edit"
            title="Nova Requisição"
            startIcon={<Add />}>
            Nova Requisição
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={toFilter}
            aria-label="edit"
            title="Atualizar Filtros">
            <RefreshIcon amplitude={10} />
          </Button> */}
        </Box>
      </Box>
      {data && (
        <Table
          data={data}
          isLoading={isLoading}
          selectedRows={setSelectedRows}
          handlerDesaprovar={handlerDesaprovar}
        />
      )}
      <RightDrawer
        caption="Filtros"
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        setOpen={setOpenDrawer}>
        {/* filtros */}
        <Box
          display="flex"
          flexDirection={'column'}
          gap={1}
          sx={{ overflow: 'hidden', overflowY: 'auto' }}>
          {/* filtro de data */}
          <PeriodFilter
            dataKey="created_at"
            value={filters.created_at ?? [null, null]}
            onChange={handlerFilter}
            caption="Período"
          />

          {/* filtro de tipos */}
          <SelectFilter
            dataKey="tipoReq"
            value={filters.tipoReq ?? []}
            data={
              dataTipo
                ? dataTipo.map((el) => ({
                    id: el.id,
                    desc: el.nome.toUpperCase()
                  }))
                : []
            }
            onChange={handlerFilter}
            caption="Tipo de Requisição"
            width={drawerWidth - 20}
          />

          {/* filtro de classes */}
          <SelectFilter
            dataKey="classeId"
            value={filters.classeId ?? []}
            loading={isLoadingClasses}
            data={
              dataClasses
                ? dataClasses
                    .filter((c) => c.ativo === 1)
                    .map((el) => ({
                      id: el.id,
                      desc: el.descricao.toUpperCase()
                    }))
                : []
            }
            onChange={handlerFilter}
            caption="Classes"
            width={drawerWidth - 20}
          />

          {/* filtro de situações */}
          <SelectFilter
            dataKey="situacaoId"
            value={filters.situacaoId ?? []}
            loading={isLoadingSituacoes}
            data={
              dataSituacoes
                ? dataSituacoes
                    .filter((s) => s.tela === 'R')
                    .map((el) => ({
                      id: el.id,
                      desc: el.nome.toUpperCase(),
                      corFundo: el.corFundo,
                      corLetra: el.corLetra
                    }))
                : []
            }
            onChange={handlerFilter}
            caption="Situações"
            width={drawerWidth - 20}
          />

          {/* filtro de prioridades */}
          <SelectFilter
            dataKey="prioridadeId"
            value={filters.prioridadeId ?? []}
            loading={isLoadingPrioridade}
            data={
              dataPrioridade?.map((el) => ({
                id: el.id,
                desc: el.nome.toUpperCase(),
                corFundo: el.corFundo,
                corLetra: el.corLetra
              })) ?? []
            }
            onChange={handlerFilter}
            caption="Prioridades"
            width={drawerWidth - 20}
          />

          {/* filtro de solicitantes */}
          <SelectFilter
            dataKey="solicitanteId"
            value={filters.solicitanteId ?? []}
            loading={isLoadingSolicitante}
            data={
              dataSolicitante?.map((el) => ({
                id: el.id,
                desc: el.nome.toUpperCase()
              })) ?? []
            }
            onChange={handlerFilter}
            caption="Solicitantes"
            width={drawerWidth - 20}
          />

          {/* filtro de destinos */}
          <SelectFilter
            dataKey="departamentoId"
            value={filters.departamentoId ?? []}
            loading={isLoadingDepartamento}
            data={
              dataDepartamento?.map((el) => ({
                id: el.id,
                desc: el.nome.toUpperCase()
              })) ?? []
            }
            onChange={handlerFilter}
            caption="Destinos"
            width={drawerWidth - 20}
          />
        </Box>
        {/* ação de filtrar */}
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[50]
          })}
          py={1}
          display={'flex'}
          justifyContent={'space-between'}>
          {/* <Typography
            onClick={clearFilters}
            sx={(theme) => ({
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline'
              }
            })}
            variant="overline">
            Limpar Filtros
          </Typography> */}
          <Button
            fullWidth
            onClick={toFilter}
            variant="contained"
            color="primary"
            size={'small'}>
            <FilterAltOutlined amplitude={8} /> Filtrar
          </Button>
        </Box>
      </RightDrawer>
    </Inner>
  );
}
