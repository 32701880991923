import { useState } from 'react';
import FilterInterface from './filters.interface';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Stack
} from '@mui/material';

export default function SelectFilter({
  dataKey,
  caption,
  data,
  value,
  onChange,
  width,
  loading = false
}: FilterInterface) {
  const [open, setOpen] = useState(false);

  const handleClose = (e: any) => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (loading)
    return (
      <Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ my: 0.5, backgroundColor: 'common.gray' }}>
          <Stack>
            <Typography
              fontSize={11}
              fontWeight={value?.length > 0 ? 'bold' : 'normal'}
              color={'primary'}>
              {caption}
            </Typography>
            <Typography
              fontSize={12}
              sx={{ my: 0.5, backgroundColor: 'common.gray.[10]' }}>
              Carregando...
            </Typography>
          </Stack>
        </Box>
      </Box>
    );

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Typography
          fontSize={11}
          fontWeight={value?.length > 0 ? 'bold' : 'normal'}
          color={'primary'}>
          {caption}
        </Typography>
        {value.length > 0 && (
          <Typography
            fontSize={9}
            onClick={() => {
              onChange({ [dataKey]: [] });
            }}
            sx={(theme) => ({
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline'
              }
            })}
            variant="overline">
            Limpar
          </Typography>
        )}
      </Box>
      {data && (
        <Select
          id={'id-filter-' + dataKey}
          size={'small'}
          sx={{
            fontSize: 10,
            width: width ?? '100%',
            backgroundColor: '#fff'
          }}
          multiple={true}
          role="listbox"
          value={value}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={(e) => {
            const {
              target: { value }
            } = e;
            const filter = typeof value === 'string' ? value.split(',') : value;
            onChange({ [dataKey]: filter });
          }}
          renderValue={(selected) =>
            selected
              .reduce((acc: string[], opt: number) => {
                const idx = data.findIndex((sit) => {
                  return sit.id === opt;
                });
                if (data[idx]?.desc) acc.push(data[idx].desc);
                return acc;
              }, [])
              .join(', ')
          }>
          {data.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox
                size="small"
                checked={value?.indexOf(option.id ?? 0) > -1}
              />
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 11,
                  bgcolor: option?.corFundo ?? 'transparent',
                  color: option?.corLetra ?? 'inherit',
                  padding: 1,
                  borderRadius: '15px'
                }}
                primary={option.desc}
              />
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  );
}
