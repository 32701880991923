import { Add, NavigateNext } from '@mui/icons-material';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';

import { Link, useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { Departamento } from './models';
import { useGetDepartamentosQuery } from './apiSlice';
import Paper from '@mui/material/Paper';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function Departamentos() {
  const { isLoading, data } = useGetDepartamentosQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );
  const navigate = useNavigate();

  function mapDataToGridRows(data: Departamento[]) {
    return data.map((d) => ({
      id: d.id,
      nome: d.nome,
      numeroSerie: d.numeroSerie
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // function RenderActionsCell(params: GridRenderCellParams) {
  //   const [deleteDepartamento, status] = useDeleteDepartamentoMutation();
  //   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  //   const { enqueueSnackbar } = useSnackbar();

  //   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const handleDelete = () => {
  //     deleteDepartamento({ id: params.id as string });
  //   };

  //   useEffect(() => {
  //     if (status.isSuccess) {
  //       enqueueSnackbar('Departamento Excluído!', { variant: 'success' });
  //     }
  //     if (status.error) {
  //       enqueueSnackbar('Error ao Excluir Departamento', { variant: 'error' });
  //     }
  //   }, [enqueueSnackbar, status.error, status.isSuccess]);

  //   const open = Boolean(anchorEl);
  //   const id = open ? 'simple-popover' : undefined;
  //   return (
  //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //       <IconButton
  //         component={Link}
  //         color="secondary"
  //         to={AppConfig.routes.cadDepartamentoEdit(params.id as string)}
  //         aria-label="edit"
  //         data-testid="edit-button"
  //         title="Editar">
  //         <Edit />
  //       </IconButton>
  //       <IconButton
  //         onClick={handleClick}
  //         color="error"
  //         aria-describedby={id}
  //         aria-label="delete"
  //         data-testid="delete-button"
  //         title="Excluir">
  //         <Delete />
  //       </IconButton>
  //       <Popover
  //         id={id}
  //         open={open}
  //         anchorEl={anchorEl}
  //         onClose={handleClose}
  //         anchorOrigin={{
  //           vertical: 'bottom',
  //           horizontal: 'left'
  //         }}>
  //         <Button onClick={handleDelete} variant="text">
  //           Excluir?
  //         </Button>
  //       </Popover>
  //     </Box>
  //   );
  // }

  return (
    <Inner>
      <Box
        sx={{ my: 1 }}
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography>Cadastro</Typography>
          <Typography fontWeight={'bold'}>Destinos</Typography>
        </Breadcrumbs>
        <Button
          startIcon={<Add />}
          component={Link}
          variant="contained"
          to={AppConfig.routes.cadDepartamentoInsert}>
          Inserir
        </Button>
      </Box>
      <Paper>
        <Box height="78vh">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: 12
              },
              fontSize: 12
            }}
            loading={isLoading}
            paginationMode="server"
            rows={data ? mapDataToGridRows(data) : []}
            columns={[
              {
                field: 'nome',
                headerName: 'Nome',
                flex: 1,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                },
                disableColumnMenu: true
              }
              // {
              //   field: 'id',
              //   headerName: 'Ações',
              //   width: 100,
              //   disableColumnMenu: true,
              //   sortable: false,
              //   renderCell: RenderActionsCell
              // }
            ]}
            pageSize={10}
            onRowDoubleClick={(params: GridRowParams) =>
              navigate(
                AppConfig.routes.cadDepartamentoEdit(params.id as string)
              )
            }
          />
        </Box>
      </Paper>
    </Inner>
  );
}
