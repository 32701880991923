import { styled, Grid } from '@mui/material';
import RequisicoesCount from './requisicoesCount';

const Inner = styled('div')(({ theme }) => ({
  height: '81vh'
}));

export default function Dashboard() {
  return (
    <Inner>
      <RequisicoesCount />
    </Inner>
  );
}
