import {
  Grid,
  FormLabel,
  Box,
  Button,
  Divider,
  Badge,
  Chip
} from '@mui/material';
import React from 'react';
import ManagerPermissao from '../../components/ManagerPermissaoSimple';
import { EnumPermissao } from '../auth/models';
import { FormikProps } from 'formik';
import { User } from './models';

interface Props {
  formik: FormikProps<User>;
  reset: () => void;
  applyAll: () => void;
}

export default function Permissoes({ formik, reset, applyAll }: Props) {
  const [render, setRender] = React.useState(0);
  function handleResetPermissoes() {
    reset();
    setRender(render + 1);
  }

  function handleApplyAll() {
    applyAll();
    setRender(render + 1);
  }

  return (
    <Box>
      <Box
        sx={(theme) => ({
          // bgcolor: theme.palette.grey[100],
          py: theme.spacing(1),
          px: theme.spacing(2),
          borderRadius: theme.shape.borderRadius
        })}
        display={'flex'}
        justifyContent={'space-between'}
        gap={3}
        my={2}>
        <Chip label="Permissões" />
        <Box display={'flex'} gap={1}>
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={handleApplyAll}>
            Conceder Todas Permissões
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={handleResetPermissoes}>
            Remover Todas Permissões
          </Button>
        </Box>
      </Box>
      {/* <Divider variant="middle" /> */}
      <Grid container columns={12}>
        {/* prioridade */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['c', 'r', 'u', '', '', '', '']}
            caption="Prioridade"
            value={
              formik.values.permissoes[EnumPermissao.PRIORIDADE]?.split('') ||
              ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.PRIORIDADE}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
        {/* situacao */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['c', 'r', 'u', '', '', '', '']}
            caption="Situação"
            value={
              formik.values.permissoes[EnumPermissao.SITUACAO]?.split('') || ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.SITUACAO}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
        {/* solicitante */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['c', 'r', 'u', '', '', '', '']}
            caption="Solicitante"
            value={
              formik.values.permissoes[EnumPermissao.SOLICITANTE]?.split('') ||
              ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.SOLICITANTE}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
        {/* departamento */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['c', 'r', 'u', '', '', '', '']}
            caption="Destino"
            value={
              formik.values.permissoes[EnumPermissao.DEPARTAMENTO]?.split('') ||
              ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.DEPARTAMENTO}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
        {/* fornecedor */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['c', 'r', 'u', '', '', '', '']}
            caption="Fornecedor"
            value={
              formik.values.permissoes[EnumPermissao.FORNECEDOR]?.split('') ||
              ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.FORNECEDOR}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
        {/* requisicao */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['c', 'r', 'u', '', 'a', 'x', '']}
            caption="Requisição"
            value={
              formik.values.permissoes[EnumPermissao.REQUISICAO]?.split('') ||
              ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.REQUISICAO}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
        {/* cotacao */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['c', 'r', 'u', '', '', '', 'f']}
            caption="Cotação"
            value={
              formik.values.permissoes[EnumPermissao.COTACAO]?.split('') || ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.COTACAO}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
        {/* pendencia  */}
        <Grid item xs={3}>
          <ManagerPermissao
            visibles={['', 'r', '', '', '', '', '']}
            caption="Pendência"
            value={
              formik.values.permissoes[EnumPermissao.PENDENCIA]?.split('') || ''
            }
            onChange={(value) => {
              formik.setFieldValue(
                `permissoes[${EnumPermissao.PENDENCIA}]`,
                `${value.join('')}`
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
