import React, { useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { LastMovim } from '../../produto/models';
import { useFindFichaSGIMutation } from '../../produto/apiSlice';

interface Props {
  value: number;
  index: number;
  formik: any;
  isFetching?: boolean;
}
export default function TabPanelLastMovims({
  value,
  index,
  formik,
  isFetching = false
}: Props) {
  const [findFichaSGI] = useFindFichaSGIMutation();

  const [rows, setRows] = React.useState<LastMovim[]>([]);
  const [codigoSgi, setCodigoSgi] = React.useState<string>('');
  const [local, setLocal] = React.useState<string>('');

  useEffect(() => {
    if (formik?.values?.produtoServico) {
      setCodigoSgi(formik.values.produtoServico.codigoSgi);
    }

    if (formik?.values?.local) {
      setLocal(formik.values.local);
    }
  }, [formik.values.produtoServico, formik.values.local]);

  useEffect(() => {
    if (codigoSgi && local) {
      findFichaSGI({ codigoSgi }).then(({ data }: any) => {
        setRows(data?.movimentacao ?? []);
      });
    }
  }, [codigoSgi, findFichaSGI, local]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      <Box sx={{ height: `78vh`, p: 2 }}>
        <TableContainer component={Paper} sx={{ height: '73vh' }}>
          <Table
            stickyHeader
            sx={{
              minWidth: 650,
              fontSize: 12
            }}
            size="small"
            aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Data</TableCell>
                <TableCell align="left">Tipo</TableCell>
                <TableCell align="left">Espécie</TableCell>
                <TableCell align="left">Série</TableCell>
                <TableCell align="left">Nota Fiscal</TableCell>
                <TableCell align="left">Qtde</TableCell>
                <TableCell align="left">P. Custo</TableCell>
                <TableCell align="left">IPI</TableCell>
                <TableCell align="left">V. IPI</TableCell>
                <TableCell align="left">ICMSST</TableCell>
                <TableCell align="left">Fornecedor</TableCell>
                <TableCell align="left">CNPJ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    fontSize: 10
                  }}>
                  <TableCell component="th" scope="row">
                    {new Date(row.data).toLocaleDateString('pt-BR')}
                  </TableCell>
                  <TableCell align="left">{row.tipomovdesc}</TableCell>
                  <TableCell align="left">{row.especie}</TableCell>
                  <TableCell align="left">{row.serie}</TableCell>
                  <TableCell align="left">{row.nfiscal}</TableCell>
                  <TableCell align="left">{row.qtde}</TableCell>
                  <TableCell align="left">
                    {Number(row.pcusto).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </TableCell>
                  <TableCell align="left">
                    {Number(row.ipi).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </TableCell>
                  <TableCell align="left">
                    {Number(row.valoripi).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </TableCell>
                  <TableCell align="left">
                    {Number(row.valoricmsst).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </TableCell>
                  <TableCell align="left">{row.nome}</TableCell>
                  <TableCell align="left">{row.cnpj}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
