import { Box, Typography } from '@mui/material';

interface Props {
  text: string;
  fontSize: number;
  gap?: number;
  fontWeight?: string;
  color?: string;
}

export default function VerticalText({
  text,
  fontSize,
  fontWeight = 'normal',
  gap = 1,
  color = 'primary'
}: Props) {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
      gap={gap}>
      {text.split('').map((word, idx) => (
        <Typography
          key={idx}
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}>
          {word}
        </Typography>
      ))}
    </Box>
  );
}
