import { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { Produto } from '../models';
import { DataGrid } from '@mui/x-data-grid';
import { useFindProdutosSGIMutation } from '../apiSlice';
import { FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useAppSelector } from '../../../app/hooks';
import { Empresa } from '../../empresa/models';
import { selectEmpresa } from '../../empresa/storeSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  px: 1,
  py: 1,
  borderRadius: 1,
  height: '87vh'
};

interface Props {
  action?: (produto: Produto, pLocal: string) => void;
  openModal?: boolean;
  onClose?: (value: boolean) => void;
  buttonIcon?: boolean;
  textSearch: string;
}

export default function SearchProdutoModal({
  action,
  textSearch,
  openModal = false,
  onClose,
  buttonIcon = false
}: Props) {
  const empresa: Empresa = useAppSelector(selectEmpresa);
  const ref = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSearchFor('');
    setFlag(true);
    if (onClose) onClose(false);
  };

  useEffect(() => {
    if (!open) {
      setFlag(true);
    }
  }, [open]);

  const [searchFor, setSearchFor] = useState<string>('');
  const [data, setData] = useState<Produto[]>([]);
  const [findProdutosSGI, status] = useFindProdutosSGIMutation();
  const [pLocal, setPLocal] = useLocalStorage<string>(
    'serach-produto-sgi-local',
    `('${empresa.localEstoquePadrao}')`
  );

  const findProdutos = useCallback(
    async (local?: string, textSearch?: string) => {
      const { produtos } = await findProdutosSGI({
        searchFor: textSearch ?? searchFor,
        pLocal: empresa.usarLocaisEstoque
          ? local || pLocal
          : `('${empresa.localEstoquePadrao}')`
      }).unwrap();
      if (produtos) {
        setData(produtos);
      } else {
        setData([]);
      }
    },
    [
      findProdutosSGI,
      searchFor,
      empresa.usarLocaisEstoque,
      empresa.localEstoquePadrao,
      pLocal
    ]
  );

  async function handleLocal(value: string) {
    if (!searchFor) return;
    setPLocal(value);
    if (searchFor.length < 3) return;
    await findProdutos(value);
  }

  function mapProduto(produto: Produto[]): Partial<Produto>[] {
    return produto.map((p: Produto, index) => ({
      id: index,
      codigo: p.codigo,
      descricao: p.descricao,
      unidade: p.unidade,
      saldo_atual: p.saldo_atual,
      precocusto: p.precocusto
    }));
  }

  useEffect(() => {
    ref.current?.focus();
    ref.current?.select();
    // ref.current?.value = '123' ?? '';
  }, [open]);

  useEffect(() => {
    if (open && flag) {
      setSearchFor(textSearch);
      findProdutos(undefined, textSearch);
      setFlag(false);
    }
  }, [open, flag, textSearch, findProdutos]);

  useEffect(() => {
    if (openModal) {
      handleOpen();
    }
  }, [open, openModal]);

  return (
    <Box>
      {buttonIcon ? (
        <IconButton onClick={handleOpen} title="Pesquisar Produtos">
          <SearchIcon />
        </IconButton>
      ) : (
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Pesquisar Produtos
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        inputMode="search"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography sx={{ px: 1 }} variant="h6" component="h6">
            Consulta Produtos
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>

            <StyledInputBase
              multiline={false}
              ref={ref}
              inputRef={ref}
              autoFocus={true}
              placeholder="Pesquisar produto…"
              inputProps={{ type: 'search' }}
              onChange={(e) => setSearchFor(e.target.value)}
              value={searchFor}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && searchFor && searchFor.length > 2) {
                  findProdutos();
                }
              }}
            />
            {empresa.usarLocaisEstoque && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                  width: '370px'
                }}>
                <Typography sx={{ fontSize: 14 }}>Local:</Typography>
                <RadioGroup
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  value={pLocal}
                  onChange={(e, v) => handleLocal(v)}>
                  <FormControlLabel
                    sx={{ fontSize: 12 }}
                    value={"('00')"}
                    label="00"
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    sx={{ fontSize: 12 }}
                    value={"('01')"}
                    label="O1"
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    sx={{ fontSize: 12 }}
                    value={"('02')"}
                    label="02"
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </Box>
            )}
          </Search>
          <Box height={'67vh'}>
            <Typography
              variant="body2"
              sx={{ ml: 1, fontSize: 10, color: '#ff0000' }}>
              (*) Para selecionar um produto, clique duas vezes sobre o
              registro.
            </Typography>
            <DataGrid
              editMode="row"
              sx={{
                '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  fontSize: 10,
                  color: '#ffffff',
                  backgroundColor: 'primary.main'
                },
                fontSize: 10,
                backgroundColor: '#ffffff'
              }}
              loading={status.isLoading}
              rows={data ? mapProduto(data) : []}
              density="compact"
              columns={[
                {
                  field: 'codigo',
                  headerName: 'Código',
                  width: 80,
                  sortable: false,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 1,
                  sortable: false,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'unidade',
                  headerName: 'Und',
                  width: 70,
                  sortable: false,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'saldo_atual',
                  headerName: 'Saldo',
                  width: 70,
                  sortable: false,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true
                },
                {
                  field: 'precocusto',
                  headerName: 'Custo',
                  width: 120,
                  sortable: false,
                  editable: false,
                  filterable: false,
                  hideable: false,
                  disableColumnMenu: true,
                  valueFormatter: (params) => {
                    return params.value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    });
                  }
                }
              ]}
              pageSize={10}
              rowsPerPageOptions={[10]}
              onRowDoubleClick={(e) => {
                if (action) {
                  action(e.row as Produto, pLocal);
                  handleClose();
                }
              }}
              onCellKeyDown={({ row }, { key }) => {
                if (key === 'Enter' && action) {
                  action(row as Produto, pLocal);
                  handleClose();
                }
              }}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.primary.main, 0.25)
  // },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  },
  marginBottom: theme.spacing(2),
  border: '1px solid #ccc',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width')
    // backgroundColor: theme.palette.background.default
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch'
    //   }
    // }
  },
  variant: 'outlined'
}));
