/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useMemo, useState } from 'react';
import 'react-data-grid/lib/styles.css';

import DataGrid, { SortColumn } from 'react-data-grid';
import {
  Box,
  Divider,
  Fade,
  IconButton,
  List,
  ListItemButton,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import { CotacaoFornecedor, CotacaoItem } from '../../models';
import columns from './columns';
import { TRow, rowKeyGetter } from './rows';
import './styles.css';
import { Grading, SaveAlt } from '@mui/icons-material';
import ModalPreencherLote from './modalPreencherLote';
import PageLoading from '../../../../components/PageLoading';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import PopoverColumns from './popoverColumns';
import { FinishCotacao, useCotacaoFinalizarMutation } from '../../apiSlice';
import ConfirmModal from '../../../../components/ConfirmModal';
import { exportToCsv, exportToPdf } from '../../../../utils/exportUtils';
import { writeFile, utils } from 'xlsx';

interface DataGridProps {
  items: CotacaoItem[];
  cotacaoId?: number;
  updated_at?: string;
  loading?: boolean;
  height?: string;
  disabled?: boolean;
  width?: string;
  isChanged?: (value: boolean) => void;
  fornecedor: CotacaoFornecedor;
}

export default function DataGridItemsGerarPedido({
  items,
  cotacaoId = 0,
  updated_at = '',
  loading = false,
  height,
  disabled = false,
  isChanged,
  width = '100%',
  fornecedor
}: DataGridProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [cotacaoFinalizar, cotacaoFinalizarStatus] =
    useCotacaoFinalizarMutation();
  const [rows, setNewRows] = useState<TRow[]>([]);
  const [changes, setChanges] = useState<TRow[]>([]);

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<number> => new Set()
  );
  // popper exportar
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculaTotal = useCallback((item: CotacaoItem) => {
    const precoComImposto = calculatePrecoComImposto(item);
    return Math.round(precoComImposto * item.qtde * 100) / 100;
  }, []);

  const [visebleColumns, setVisebleColumns] = useLocalStorage<string[]>(
    'data-grid-item-resume-columns',
    columns.map((column) => column.key)
  );

  const setRows = useCallback(
    (newRows: TRow[]) => {
      console.log('🚀 ~ file: index.tsx:85 ~ newRows:', newRows);
      const map = newRows.map((row) => {
        const precoComImposto = calculatePrecoComImposto(row as any);
        const total = calculaTotal(row as any);
        const newRows = { ...row, precoComImposto, total };
        return newRows;
      });
      setNewRows(map);
    },
    [calculaTotal]
  );

  function handleProcessLote(data: Record<string, any>) {
    // campos
    const fields = Object.entries(data);
    const fieldsFilter = fields.filter((item) => !!item[1]);
    const fieldsMap = fieldsFilter.reduce(
      (acc: Record<string, number>, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {}
    );
    const newRows = rows.map((row) => {
      const rowId = row.id;
      if (selectedRows.has(rowId)) {
        return { ...row, ...fieldsMap };
      } else {
        return row;
      }
    });
    setRows(newRows);
    setSelectedRows(new Set());
  }

  // map items to rows
  useEffect(() => {
    const map = items.map((item) => ({
      id: item.id,
      situacao: item.situacao,
      updated_at: item.requisicao?.updatedAt ?? '',
      codigoSgi: item?.requisicao?.produtoServico?.codigoSgi ?? '',
      descricao: item?.requisicao?.descricaoProdutoServico ?? '',
      fornecedorId: item?.fornecedor?.id ?? 0,
      requisicaoId: item?.requisicao?.id ?? 0,
      qtde: item.qtde,
      preco: item.preco,
      icms: item.icms,
      ipi: item.ipi,
      frete: item.frete,
      outrasDespesas: item.outrasDespesas,
      precoComImposto: item.precoComImposto,
      seuCodigo: item.seuCodigo,
      total: item.qtde * item.precoComImposto,
      obs: item.obs,
      previsao: item?.previsao ?? null,
      pedido: item?.requisicao?.pedido
    }));
    setNewRows(map.reverse() as any);
    // setOldRows([map]);
  }, [calculaTotal, items]);

  const getComparator = useCallback((sortColumn: string) => {
    switch (sortColumn) {
      //-> string sort
      case 'descricao':
        return (a: any, b: any) => {
          return a[sortColumn].localeCompare(b[sortColumn]);
        };

      //-> boolean sort
      // case '':
      //   return (a, b) => {
      //     return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      //   };

      //-> date sort
      case 'previsao':
        return (a: any, b: any) => {
          return (
            new Date(a[sortColumn]).getTime() -
            new Date(b[sortColumn]).getTime()
          );
        };

      case 'codigoSgi':
      case 'seuCodigo':
      case 'qtde':
      case 'preco':
      case 'precoComImposto':
      case 'total':
      case 'requisicaoId': // => number
        return (a: any, b: any) => {
          return Number(a[sortColumn]) - Number(b[sortColumn]);
        };
      default:
        enqueueSnackbar(`Não é possível ordenar a coluna: "${sortColumn}"`, {
          variant: 'error'
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const sortedRows = useMemo((): readonly any[] => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        if (!comparator) {
          return 0;
        }
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [getComparator, rows, sortColumns]);

  function calculatePrecoComImposto(item: CotacaoItem) {
    const preco = item.preco ?? 0;
    const icms = item.icms ? preco * (item.icms / 100) : 0;
    const ipi = item.ipi ? preco * (item.ipi / 100) : 0;
    const frete = item.frete ? preco * (item.frete / 100) : 0;
    const outrasDespesas = item.outrasDespesas
      ? preco * (item.outrasDespesas / 100)
      : 0;
    return preco + icms + ipi + frete + outrasDespesas;
  }

  // veficar se tem alteração
  useEffect(() => {
    const reduce = rows.reduce((acc: TRow[], row: TRow) => {
      const item = items.find((item) => item.id === row.id);
      if (item) {
        const modificado =
          row.obs !== item.obs || row.previsao !== item.previsao;
        if (modificado) {
          acc.push(row);
        }
      }
      return acc;
    }, []);
    setChanges(reduce);
  }, [rows, items]);

  // calcula total geral
  const [totalGeral, setTotalGeral] = useState(0);
  useEffect(() => {
    const total = rows.reduce((acc, row) => {
      return acc + row.total || 0;
    }, 0);
    setTotalGeral(total);
  }, [rows]);

  // previne refresh da pagina sem estar salvo
  useEffect(() => {
    if (!isChanged) return;
    if (changes.length > 0) {
      isChanged(true);
    } else {
      isChanged(false);
    }
  }, [changes, isChanged]);

  // fim previne refresh da pagina sem estar salvo

  function makePedido(): void {
    const validItems = rows.filter((item) => item.pedido === null);

    // valida previsao invalida
    const previsaoInvalida = rows.filter((item) => !item.previsao);
    if (previsaoInvalida.length > 0) {
      enqueueSnackbar(
        `Informe a previsão de entrega: ${previsaoInvalida[0].descricao}
        ${
          previsaoInvalida.length > 1
            ? `, e mais ${previsaoInvalida.length - 1} outro(s) itens...`
            : ''
        }
        `,
        {
          variant: 'warning',
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        }
      );
      return;
    }

    // let hasError = false;
    // validItems.forEach((item) => {
    //   if (!item.previsao) {
    //     enqueueSnackbar(`Informe a previsão de entrega: ${item.descricao}`, {
    //       variant: 'error'
    //     });
    //     hasError = true;
    //   }
    // });
    // if (hasError) {
    //   return;
    // }

    if (!fornecedor?.fornecedor?.id) {
      enqueueSnackbar(`Fornecedor não definido`, {
        variant: 'error'
      });
      return;
    }

    const mapRequisicoes = validItems.map((item: any) => ({
      requisicaoId: item.requisicaoId || item.requisicao.id,
      updated_at: item.updated_at,
      obs: item.obs,
      previsao: item.previsao
    }));
    const pedidos: any[] = [];
    pedidos.push({
      fornecedorId: fornecedor.fornecedor.id,
      requisicoes: mapRequisicoes
    });

    const finish: FinishCotacao = {
      cotacao: {
        id: cotacaoId,
        updated_at: updated_at,
        pedidos
      }
    };

    // console.log('finish => ', finish);

    cotacaoFinalizar(finish)
      .then((response: any) => {
        if (response.error) {
          enqueueSnackbar(`Erro ao enviar pedido`, {
            variant: 'error'
          });
        } else {
          enqueueSnackbar(`Pedido enviado com sucesso`, {
            variant: 'success'
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(`Erro ao enviar pedido`, {
          variant: 'error'
        });
      });
  }

  const dataGrid = useMemo(
    () => (
      <DataGrid
        className="rdg-light"
        style={{ height: '100%', width: '100%' }}
        columns={columns.filter(
          (column) =>
            visebleColumns.indexOf(column.key) !== -1 ||
            column.key === 'select-row'
        )}
        // columns={columns}
        rows={sortedRows}
        defaultColumnOptions={{
          sortable: true
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        rowKeyGetter={rowKeyGetter}
        onRowsChange={setRows}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
      />
    ),
    [sortedRows, selectedRows, setRows, visebleColumns, sortColumns]
  );

  function exportCsv() {
    if (dataGrid) {
      exportToCsv(dataGrid, 'cotacao-precos-externo');
      toggleOpen({} as any);
    }
  }

  function exportPdf() {
    if (dataGrid) {
      exportToPdf(dataGrid, 'cotacao-precos-externo');
      toggleOpen({} as any);
    }
  }

  const mapToExcel = ((rows as any[]) ?? []).map((d) => {
    return {
      Req: d.requisicaoId,
      Descrição: d.descricao,
      'Código SGI': d.codigoSgi,
      'Código Fornecedor': d.seuCodigo,
      Qtde: d.qtde,
      Preço: d.preco,
      'Preço/Imposto': d.precoComImposto,
      Total: d.total,
      Previsão: new Date(d.previsao).toLocaleDateString('pt-BR'),
      Observação: d.obs
    };
  });

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'Cotacao-Pedido.xlsx');
  }

  const isLoading = loading;

  return (
    <Box width={width || '100%'}>
      <Paper
        sx={{
          p: 2,
          my: 1,
          height: 50,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Box sx={{ flex: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
          <PopoverColumns
            value={visebleColumns}
            onChange={setVisebleColumns}
            activeColumns={[
              'seuCodigo',
              'codigoSgi',
              'obs',
              'situacao',
              'preco',
              'precoComImposto',
              'total'
            ]}
          />
          <Box>
            <IconButton size="small" color="primary" onClick={toggleOpen}>
              <SaveAlt />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={'bottom-start'}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <List>
                      <ListItemButton onClick={handleExport}>
                        Exportar Excel
                      </ListItemButton>
                      <Divider />
                      <ListItemButton onClick={exportPdf}>
                        Exportar PDF
                      </ListItemButton>
                    </List>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Typography fontSize={12} variant="h6" component="span">
            Requisições Vencedoras:
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={'bold'}
            variant="h6"
            component="span">
            {rows.length}
          </Typography>
          {/* <Divider orientation="vertical" flexItem /> */}
          {/* <CircularProgressLabel
            color="success"
            value={Math.round((totalCotado / total) * 100)}
          /> */}
          <Divider orientation="vertical" flexItem />
          <Typography fontSize={12} variant="h6" component="span">
            Total:
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={'bold'}
            variant="h6"
            component="span">
            {(totalGeral ?? 0).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })}
          </Typography>
        </Box>
        <Box display={'flex'} gap={2}>
          <ModalPreencherLote
            disabled={disabled || selectedRows.size === 0}
            action={handleProcessLote}
            selectesRows={selectedRows.size > 0}
          />
          <ConfirmModal
            component={
              <LoadingButton
                disabled={disabled}
                loading={cotacaoFinalizarStatus.isLoading}
                variant="contained"
                color="success"
                startIcon={<Grading />}>
                Gerar Pedido
              </LoadingButton>
            }
            action={makePedido}
            title={'Gerar Pedido'}
            description={`Confirmar pedido para o fornecedor: ${fornecedor.fornecedor.nome}?`}
          />
        </Box>
      </Paper>
      <Paper sx={{ overflow: 'hidden', overflowX: 'auto' }}>
        <Box
          sx={{
            overflow: 'hidden',
            overflowX: 'auto'
          }}
          height={height}>
          {isLoading ? <PageLoading minHeight={50} /> : dataGrid}
        </Box>
      </Paper>
    </Box>
  );
}
