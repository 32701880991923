/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { encryptor, decryptor } from '../../utils/enc-dec';
import { parseLocalStorgeKey } from '../../utils/functions';

const initialState = () => {
  const token = window.localStorage.getItem(parseLocalStorgeKey('token'));
  const rtoken = window.localStorage.getItem(parseLocalStorgeKey('rtoken'));
  const user = window.localStorage.getItem(parseLocalStorgeKey('user'));
  return {
    user: user ? JSON.parse(decryptor(user)) : null,
    token,
    rtoken,
    isAuthenticated: user !== null && token !== null
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { usuario, accessToken, refreshToken } = action.payload;
      window.localStorage.setItem(parseLocalStorgeKey('token'), accessToken);
      window.localStorage.setItem(parseLocalStorgeKey('rtoken'), refreshToken);
      window.localStorage.setItem(
        parseLocalStorgeKey('user'),
        encryptor(JSON.stringify(usuario))
      );
      state.user = usuario;
      state.token = accessToken;
      state.rtoken = refreshToken;
      state.isAuthenticated = true;
    },
    logOut: (state) => {
      window.localStorage.removeItem(parseLocalStorgeKey('token'));
      window.localStorage.removeItem(parseLocalStorgeKey('rtoken'));
      window.localStorage.removeItem(parseLocalStorgeKey('user'));
      state.user = null;
      state.token = null;
      state.rtoken = null;
      state.isAuthenticated = false;
    }
  }
});

export const { setCredentials, logOut } = authSlice.actions;

export const selectCurrentUser = (state: any) => state.auth.user;
export const selectCurrentToken = (state: any) => state.auth.token;
export const selectIsAuthenticated = (state: any) => state.auth.isAuthenticated;

export default authSlice.reducer;
