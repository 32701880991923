/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

const initialState = () => ({});

const empresaSlice = createSlice({
  name: 'empresa',
  initialState,
  reducers: {
    setEmpresa: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    }
  }
});

export const { setEmpresa } = empresaSlice.actions;

export const selectEmpresa = (state: any) => state.empresa;
export default empresaSlice.reducer;
