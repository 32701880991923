const AppConfig = {
  name: process.env.REACT_APP_NAME || 'COTAÇÃO',
  localStorageName: window.location.origin + '@',
  drawerWidth: 240,
  routes: {
    dashboard: '/',
    // cadastro

    // user
    cadUser: '/cadastro/usuario',
    cadUserInsert: '/cadastro/usuario/inserir',
    cadUserEdit: (id?: string): string =>
      id ? '/cadastro/usuario/editar/' + id : '/cadastro/usuario/editar/:id',

    // prioridade
    cadPrioridade: '/cadastro/prioridade',
    cadPrioridadeInsert: '/cadastro/prioridade/inserir',
    cadPrioridadeEdit: (id?: string): string =>
      id
        ? '/cadastro/prioridade/editar/' + id
        : '/cadastro/prioridade/editar/:id',

    // situacao
    cadSituacao: '/cadastro/situacao',
    cadSituacaoInsert: '/cadastro/situacao/inserir',
    cadSituacaoEdit: (id?: string): string =>
      id ? '/cadastro/situacao/editar/' + id : '/cadastro/situacao/editar/:id',

    // departamento
    cadDepartamento: '/cadastro/departamento',
    cadDepartamentoInsert: '/cadastro/departamento/inserir',
    cadDepartamentoEdit: (id?: string): string =>
      id
        ? '/cadastro/departamento/editar/' + id
        : '/cadastro/departamento/editar/:id',

    // solicitante
    cadSolicitante: '/cadastro/solicitante',
    cadSolicitanteInsert: '/cadastro/solicitante/inserir',
    cadSolicitanteEdit: (id?: string): string =>
      id
        ? '/cadastro/solicitante/editar/' + id
        : '/cadastro/solicitante/editar/:id',

    // fornecedor
    cadFornecedor: '/cadastro/fornecedor',
    cadFornecedorInsert: '/cadastro/fornecedor/inserir',
    cadFornecedorEdit: (id?: string): string =>
      id
        ? '/cadastro/fornecedor/editar/' + id
        : '/cadastro/fornecedor/editar/:id',

    // requisicao
    cadRequisicao: '/requisicao',
    cadRequisicaoInsert: '/requisicao/inserir',
    cadRequisicaoEdit: (id?: string): string =>
      id ? '/requisicao/editar/' + id : '/requisicao/editar/:id',

    // aprovacao
    cadAprovacao: '/aprovacao',

    // cotacao
    cadCotacao: '/cotacao',
    cadCotacaoInsert: '/cotacao/nova',
    cadCotacaoEdit: (id?: string): string =>
      id ? '/cotacao/editar/' + id : '/cotacao/editar/:id',
    cadCotacaoEditDigitar: (id?: string, fornecedorId?: string): string =>
      id
        ? '/cotacao/editar/' + id + '/digitar/' + fornecedorId
        : '/cotacao/editar/:id/digitar/:fornecedorId',
    cadCotacaoEditGerarPedido: (id?: string, fornecedorId?: string): string =>
      id
        ? '/cotacao/editar/' + id + '/gerar-pedido/' + fornecedorId
        : '/cotacao/editar/:id/gerar-pedido/:fornecedorId',

    // cotacao/externo
    cotacaoExterno: '/cotacao/externo',
    cotacaoValidaExterno: '/cotacao/externo/valida/:uuid',
    cotacaoExpiradaExterno: (uuid?: string) =>
      uuid
        ? '/cotacao/externo/expirada/' + uuid
        : '/cotacao/externo/expirada/:uuid',
    cotacaoCanceladaExterno: '/cotacao/externo/cancelada',
    cotacaoNaoLocalizadaExterno: '/cotacao/externo/nao-localizada',
    cotacoesFornecedorExterno: '/cotacao/externo/cotacoes',

    // pendencia/pedido
    cadPendencia: '/pendencia',
    cadPendenciaEdit: (id?: string): string =>
      id ? '/pendencia/editar/' + id : '/pendencia/editar/:id',

    // config
    config: '/config',

    // login
    login: '/login'
  }
};

export default AppConfig;
