/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, ReactNode } from 'react';
import List from '@mui/material/List';
import {
  NavLink as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import BarChartIcon from '@mui/icons-material/BarChart';
import FolderIcon from '@mui/icons-material/Folder';
import UserIcon from '@mui/icons-material/People';

import AppConfig from '../config/app';
import {
  alpha,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip
} from '@mui/material';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Settings } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function sxIcon(theme: Theme) {
  return { color: 'inherit' };
}

const ListDropDown = ({
  title = '',
  children
}: {
  title: string;
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={() => ({ py: 0, minHeight: 32, color: 'inherit' })}>
        <ListItemIcon sx={{ color: 'inherit' }}>
          {open ? <FolderOpenIcon sx={sxIcon} /> : <FolderIcon sx={sxIcon} />}
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  menuOpened: boolean;
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
  itemProps,
  ref
) {
  return (
    <RouterLink
      ref={ref}
      // @ts-ignore
      style={({ isActive }) =>
        isActive ? { background: alpha('#cacaca', 0.2) } : undefined
      }
      {...itemProps}
      role={undefined}
    />
  );
});

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, menuOpened } = props;

  return (
    <Tooltip
      title={primary}
      arrow
      placement="right"
      disableHoverListener={menuOpened}>
      <ListItem
        component={Link}
        to={to}
        sx={() => ({ py: 0, minHeight: 32, color: 'inherit' })}>
        {icon ? (
          <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
          sx={{ my: 0 }}
        />
      </ListItem>
    </Tooltip>
  );
}

function ListSubItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, menuOpened } = props;

  return (
    <Tooltip
      title={primary}
      arrow
      placement="right"
      disableHoverListener={menuOpened}>
      <ListItem
        component={Link}
        to={to}
        sx={() => ({ py: 0, minHeight: 32, color: 'inherit', px: 3 })}>
        {icon ? (
          <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
          sx={{ my: 0 }}
        />
      </ListItem>
    </Tooltip>
  );
}

type Props = {
  menuOpened: boolean;
};

export default function SideMenu({ menuOpened }: Props) {
  return (
    <>
      <List
        sx={(theme) => ({
          background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main} 30%)`,
          // backgroundColor: alpha(theme.palette.primary.main, 1),
          height: '100%',
          overflow: 'hidden',
          overflowY: 'auto',
          color: alpha(theme.palette.primary.contrastText, 0.7)
        })}>
        <ListItemLink
          to="/"
          primary="Painel"
          icon={<BarChartIcon sx={sxIcon} />}
          menuOpened={menuOpened}
        />
        <ListDropDown title="Cadastros">
          <List>
            <ListSubItemLink
              to={AppConfig.routes.cadUser}
              primary="Usuários"
              icon={<UserIcon sx={sxIcon} />}
              menuOpened={menuOpened}
            />
            <ListSubItemLink
              to={AppConfig.routes.cadPrioridade}
              primary="Prioridades"
              icon={<LowPriorityIcon sx={sxIcon} />}
              menuOpened={menuOpened}
            />
            <ListSubItemLink
              to={AppConfig.routes.cadSituacao}
              primary="Situações"
              icon={<FormatListBulletedIcon sx={sxIcon} />}
              menuOpened={menuOpened}
            />

            <ListSubItemLink
              to={AppConfig.routes.cadSolicitante}
              primary="Solicitantes"
              icon={<EmojiPeopleIcon sx={sxIcon} />}
              menuOpened={menuOpened}
            />
            <ListSubItemLink
              to={AppConfig.routes.cadDepartamento}
              primary="Destinos"
              icon={<BusinessCenterIcon sx={sxIcon} />}
              menuOpened={menuOpened}
            />
            <ListSubItemLink
              to={AppConfig.routes.cadFornecedor}
              primary="Fornecedores"
              icon={<LocalShippingIcon sx={sxIcon} />}
              menuOpened={menuOpened}
            />
            <ListSubItemLink
              to={AppConfig.routes.config}
              primary="Configurações"
              icon={<Settings sx={sxIcon} />}
              menuOpened={menuOpened}
            />
          </List>
        </ListDropDown>
        <ListItemLink
          to={AppConfig.routes.cadRequisicao}
          primary="Requisições"
          icon={<ListAltIcon sx={sxIcon} />}
          menuOpened={menuOpened}
        />
        <ListItemLink
          to={AppConfig.routes.cadAprovacao}
          primary="Aprovação"
          icon={<PlaylistAddCheckIcon sx={sxIcon} />}
          menuOpened={menuOpened}
        />
        <ListItemLink
          to={AppConfig.routes.cadCotacao}
          primary="Cotações"
          icon={<ShoppingCartCheckoutIcon sx={sxIcon} />}
          menuOpened={menuOpened}
        />
        <ListItemLink
          to={AppConfig.routes.cadPendencia}
          primary="Pendências"
          icon={<PriorityHighIcon sx={sxIcon} />}
          menuOpened={menuOpened}
        />
      </List>
      {/* <Profile menuOpen={menuOpened} /> */}
    </>
  );
}
