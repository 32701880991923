import { ExitToApp, Check } from '@mui/icons-material';
import { Box, Modal, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

interface ComponentModalProps {
  degreeActionTitle?: string;
  degreeAction?: () => void;
  degreeActionColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  confirmActionTitle?: string;
  confirmAction?: () => void;
  confirmActionColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  title?: string;
  description?: string;
  open: boolean;
  close: () => void;
}

export default function ComponentModal({
  degreeActionTitle,
  degreeAction,
  degreeActionColor,
  confirmActionTitle,
  confirmAction,
  confirmActionColor,
  title,
  description,
  open,
  close
}: ComponentModalProps) {
  return (
    <Box>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            {title ?? 'Confirmação'}
          </Typography>
          <Box p={2} display={'flex'} justifyContent={'space-between'}>
            <Typography sx={{ flex: 1 }}>
              {description ?? 'Deseja confirmar a ação?'}
            </Typography>
          </Box>
          <Box p={2} display={'flex'} justifyContent={'space-between'}>
            {degreeAction && (
              <Button
                startIcon={<ExitToApp />}
                variant="contained"
                color={degreeActionColor ?? 'inherit'}
                onClick={() => {
                  degreeAction();
                }}>
                {degreeActionTitle ?? 'Não'}
              </Button>
            )}
            {confirmAction && (
              <Button
                variant="contained"
                startIcon={<Check />}
                color={confirmActionColor ?? 'success'}
                onClick={() => {
                  confirmAction();
                }}>
                {confirmActionTitle ?? 'Sim'}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
