import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { deserializeError } from 'serialize-error';

export function useApiError(
  isError = false,
  error: FetchBaseQueryError | SerializedError | undefined
) {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isError && error) {
      const { message } = deserializeError(error);
      const err = JSON.parse(message);
      setMessage(err.data.message);
    }
  }, [error, isError]);

  return [message] as const;
}
