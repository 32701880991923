import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import TableRequisicaoSelect from './tableRequisicaoSelect';
import { Requisicao } from '../../requisicao/models';
import { useState } from 'react';
import { Classe } from '../../classes/models';

interface Props {
  values: Requisicao[];
  onChange: (values: Requisicao[]) => void;
  requisicoes: Requisicao[];
  dataClasses: Classe[];
  filter: number[];
  setFilterClasse: (filter: number[]) => void;
}

const StepRequisicoes = ({
  values,
  onChange,
  requisicoes,
  dataClasses,
  filter,
  setFilterClasse
}: Props) => {
  // filtro classes
  // const [open, setOpen] = useState(false);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  return (
    <Box sx={{ height: '70vh', p: 2 }}>
      {/* {dataClasses.length > 0 ? (
        <Box
          sx={{
            height: '6vh',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'end'
          }}>
          <Typography variant="h6" sx={{ fontSize: 14 }}>
            Filtrar classe
          </Typography>

          <Select
            id={'id-filter-classe'}
            size={'small'}
            sx={{ fontSize: 10, backgroundColor: '#fff', minWidth: 200 }}
            multiple={true}
            role="listbox"
            value={filter}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={(e) => {
              const {
                target: { value }
              } = e;
              const filter =
                typeof value === 'string' ? value.split(',') : value;
              setFilterClasse((filter as number[]) ?? []);
            }}
            renderValue={(selected) =>
              selected
                .map((s: number) => {
                  const cl = dataClasses?.find((c) => c.id === s);
                  return cl?.descricao ?? '';
                })
                .join(', ')
            }>
            {dataClasses.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox
                  size="small"
                  checked={filter?.indexOf(option.id ?? 0) > -1}
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 11,
                    bgcolor: option?.corFundo ?? 'transparent',
                    color: option?.corLetra ?? 'inherit',
                    padding: 1,
                    borderRadius: '15px'
                  }}
                  primary={option.descricao}
                />
              </MenuItem>
            ))}
          </Select>
        </Box>
      ) : (
        <Box
          sx={{
            height: '6vh',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'end'
          }}>
          {' '}
        </Box>
      )} */}
      <TableRequisicaoSelect
        selectedRows={values}
        setSelectedRows={onChange}
        data={requisicoes}
        filter={{ values: filter, dataClasses, setFilterClasse }}
      />
    </Box>
  );
};

export default StepRequisicoes;
