/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { logOut, setCredentials } from '../auth/authSlice';

export const domain = window.location.origin;
const subdomain = domain.split('.')[0].split('//')[1];
const replace = domain.replace(subdomain, `${subdomain}api`);
export const baseUrl = process.env.REACT_APP_API_URL || replace;
// console.log('🚀 ~ file: apiSlice.ts:15 ~ baseUrl:', baseUrl);

const baseQuery = fetchBaseQuery({
  baseUrl,
  //   credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as any).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // console.log('sending refresh token');
    // send refresh token to get new access token
    const refreshResult = await baseQuery(
      '/auth/refreshtoken',
      api,
      extraOptions
    );
    console.log(refreshResult);
    if (refreshResult?.data) {
      const user = (api.getState() as any).auth.user;
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: [
    'Users',
    'User',
    'Prioridades',
    'Situacoes',
    'Departamentos',
    'Solicitantes',
    'Requisicoes',
    'Tipos',
    'Fornecedores',
    'Cotacoes',
    'Pedidos',
    'Aprovacoes',
    'CotacoesExterno',
    'CotacoesFornecedor',
    'Classes',
    'Config'
  ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builder) => ({}),
  baseQuery: baseQueryWithReauth
  //   baseQuery: fetchBaseQuery({ baseUrl })
});
