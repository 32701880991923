import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  styled,
  Typography,
  Paper,
  Tab,
  Tabs,
  Theme,
  Chip
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Cancel, NavigateNext } from '@mui/icons-material';
import { OUTLET_HEIGHT } from '../../../config/theme';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Link, useLocation, useParams } from 'react-router-dom';
import AppConfig from '../../../config/app';
import { useGetCotacaoQuery } from '../apiSlice';
import TabPanelInfo from './tabPanelInfo';
import TabPanelFornecedores from './tabPanelFornecedores';
import TabPanelRequisicoes from './tabPanelRequisicoes';
// import TabPanelItems from './tabPanelItems';
import TabPanelSelection from './tabPanelSelection';
import { useAppSelector } from '../../../app/hooks';
import { Empresa } from '../../empresa/models';
import { selectEmpresa } from '../../empresa/storeSlice';
import TabPanelFornecedoresExterno from './tabPanelFornecedoresExterno';
import { useSelector } from 'react-redux';
import { selectIsSidebarOpen } from '../../../layout/layoutSlice';
import TabPanelItemsList from './tabPanelItemsList';
import TabPanelResumeList from './tabPanelResumeList';
import { CotacaoFornecedor, CotacaoItem } from '../models';
import ConfirmModal from '../../../components/ConfirmModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

const TAB_SX = (theme: Theme, valueTab: number, index: number) => ({
  textTransform: 'none',
  fontSize: '0.8rem',
  fontWeight: valueTab === index ? 'bold' : 'inherit',
  textAlign: 'left',
  flex: 1,
  bgcolor:
    valueTab === index ? theme.palette.secondary.main : theme.palette.grey[100]
});

export interface CotacaoFornecedorMap extends CotacaoFornecedor {
  reqTotal: number;
  reqCotado: number;
  pedido: string | undefined;
}

export default function CotacaoEdit() {
  const empresa: Empresa = useAppSelector(selectEmpresa);
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const drawerOpen = useSelector(selectIsSidebarOpen);
  const id = useParams().id as string;
  const {
    data: cotacao,
    isLoading,
    isFetching
  } = useGetCotacaoQuery({ id }, { refetchOnMountOrArgChange: true });
  const [value, setValue] = useState(0);

  const fornecedoresMap: CotacaoFornecedorMap[] = useMemo(
    () =>
      cotacao?.fornecedores?.map((f) => {
        const filter =
          cotacao?.itens.filter(
            (item) =>
              item?.fornecedor?.id && f.fornecedor.id === item.fornecedor.id
          ) ?? [];

        const total = filter.length;

        const cotado = filter.filter(
          (item) =>
            item.situacao.id === 215 ||
            item.situacao.id === 220 ||
            item.situacao.id === 230
        ).length;

        const filterPedido = filter.filter(
          (ci) => ci.requisicao?.pedido?.id && ci.situacao.id === 230
        );
        // separa os pedidoId em um set
        const pedidoIdSet = new Set<string>();
        filterPedido.forEach((item) => {
          const pedidoId = item.requisicao?.pedido?.id?.toString() ?? undefined;
          if (pedidoId && f.pedidoGerado) {
            pedidoIdSet.add(pedidoId);
          }
        });
        // converte o set para array
        const reducerPedido = Array.from(pedidoIdSet);

        return {
          ...f,
          reqTotal: total,
          reqCotado: cotado,
          pedido: reducerPedido.toString()
        };
      }) ?? [],
    [cotacao?.fornecedores, cotacao?.itens]
  );

  useEffect(() => {
    if (searchParams.has('tab')) {
      const tab = searchParams.get('tab') ?? '0';
      if (parseInt(tab) > 5) {
        setSearchParams({ tab: '0' });
        return;
      }
      setValue(tab ? parseInt(tab ?? '0') : 0);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (state) {
      const { tab } = state as { tab: number };
      setSearchParams({ tab: tab.toString() });
    }
  }, [setSearchParams, state]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    setSearchParams({ tab: newValue.toString() });
  };

  const handlerCancelarCotacao = () => {
    setSearchParams({ tab: '0' });
    // aguardar API para cancelar a cotação
  };

  return (
    <Inner>
      <Box
        py={1}
        display="flex"
        alignItems={'center'}
        justifyContent="space-between">
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Link color="inherit" to={AppConfig.routes.cadCotacao}>
            <Typography>Cotações</Typography>
          </Link>
          <Typography>Alterar</Typography>
          <Typography>Cotação {cotacao?.id}</Typography>
          <Typography>{cotacao?.obs}</Typography>
          <Chip label={cotacao?.situacao.nome} />
        </Breadcrumbs>
        <Box display={'flex'} gap={2}>
          <Button
            type="button"
            component={Link}
            to={AppConfig.routes.cadCotacao}
            startIcon={<ReplyAllIcon />}
            variant="outlined">
            Voltar
          </Button>
          <ConfirmModal
            title="Cancelamento de Cotação"
            description={`Cancelar a cotação: \n ${cotacao?.obs} - ${cotacao?.situacao.nome}.`}
            action={handlerCancelarCotacao}
            component={
              <Button
                type="button"
                startIcon={<Cancel />}
                color="error"
                variant="contained">
                Cancelar cotação
              </Button>
            }
          />
        </Box>
      </Box>
      <Paper
        sx={(theme) => ({
          height: '79vh',
          overflow: 'hidden',
          maxWidth: drawerOpen
            ? `calc(97.5vw - ${AppConfig.drawerWidth}px)`
            : `calc(97vw - ${theme.spacing(7)})`,
          backgroundColor: theme.palette.background.paper,
          // cotacao?.situacao.id === 120
          //   ? theme.palette.grey[200]
          //   : theme.palette.background.paper,
          // transição
          transition: theme.transitions.create(
            ['max-width', 'background-color'],
            {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.standard
            }
          )
        })}>
        <Tabs
          value={value ?? 0}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ hidden: true }}
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.primary.main,
              height: 3
            },
            '& button.Mui-selected': {
              color: theme.palette.common.white!
            }
          })}
          // textColor="primary"
          aria-label="scrollable auto tabs">
          <Tab sx={(theme) => TAB_SX(theme, value, 0)} label="Informações" />
          <Tab sx={(theme) => TAB_SX(theme, value, 1)} label="Fornecedores" />
          <Tab sx={(theme) => TAB_SX(theme, value, 2)} label="Requisições" />
          <Tab
            sx={(theme) => TAB_SX(theme, value, 3)}
            label="Pesquisa de Preços"
          />
          <Tab sx={(theme) => TAB_SX(theme, value, 4)} label="Melhor Preço" />
          <Tab sx={(theme) => TAB_SX(theme, value, 5)} label="Resumo" />
        </Tabs>
        <TabPanelInfo
          value={value}
          index={0}
          cotacaoId={cotacao?.id ?? 0}
          updatedAt={cotacao?.updatedAt ?? ''}
          createdAt={
            cotacao?.createdAt
              ? new Date(cotacao.createdAt).toLocaleDateString('pt-br')
              : ''
          }
          obs={cotacao?.obs ?? ''}
          qtdeRequisicoes={cotacao?.requisicoes?.length ?? 0}
          qtdeFornecedores={cotacao?.fornecedores?.length ?? 0}
          status={cotacao?.situacao.nome ?? ''}
        />
        {cotacao?.id && cotacao?.updatedAt ? (
          empresa.emailCotacao ? (
            <TabPanelFornecedoresExterno
              value={value}
              index={1}
              situacao={cotacao.situacao?.id ?? 0}
              cotacaoId={cotacao.id}
              updated_at={cotacao.updatedAt.toString()}
              // data={cotacao ? cotacao?.fornecedores ?? [] : []}
              data={cotacao ? fornecedoresMap ?? [] : []}
              loading={isLoading || isFetching}
              requisicoes={cotacao ? cotacao?.requisicoes ?? [] : []}
            />
          ) : (
            <TabPanelFornecedores
              value={value}
              index={1}
              situacao={cotacao.situacao?.id ?? 0}
              cotacaoId={cotacao.id}
              updated_at={cotacao.updatedAt.toString()}
              // data={cotacao ? cotacao?.fornecedores ?? [] : []}
              data={cotacao ? fornecedoresMap ?? [] : []}
              loading={isLoading || isFetching}
            />
          )
        ) : null}
        {cotacao?.id && cotacao?.updatedAt && (
          <TabPanelRequisicoes
            value={value}
            index={2}
            situacao={cotacao.situacao?.id ?? 0}
            cotacaoId={cotacao.id}
            updated_at={cotacao.updatedAt.toString()}
            data={cotacao ? cotacao?.requisicoes ?? [] : []}
          />
        )}
        {cotacao?.id && cotacao?.updatedAt && (
          // <TabPanelItems
          <TabPanelItemsList
            value={value}
            index={3}
            cotacaoId={cotacao.id}
            situacao={cotacao.situacao?.id ?? 0}
            fornecedores={cotacao ? fornecedoresMap ?? [] : []}
            items={cotacao ? cotacao?.itens ?? [] : []}
            loading={isLoading}
          />
        )}
        {cotacao?.id && cotacao?.updatedAt && (
          <TabPanelSelection
            value={value}
            index={4}
            requisicoes={cotacao ? cotacao?.requisicoes ?? [] : []}
            situacao={cotacao.situacao?.id ?? 0}
            items={cotacao ? cotacao?.itens ?? [] : []}
            fornecedores={cotacao ? fornecedoresMap ?? [] : []}
            cotacaoId={cotacao.id}
            cotacaoUpdateAt={cotacao.updatedAt.toString()}
          />
        )}
        {cotacao?.id && cotacao?.updatedAt && (
          // <TabPanelResume
          <TabPanelResumeList
            value={value}
            index={5}
            cotacaoId={cotacao.id}
            updated_at={cotacao.updatedAt.toString()}
            situacao={cotacao.situacao?.id ?? 0}
            items={cotacao ? cotacao?.itens ?? [] : []}
            fornecedores={fornecedoresMap ?? []}
          />
        )}
      </Paper>
    </Inner>
  );
}
