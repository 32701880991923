import { object, string } from 'yup';

export const AUTH_INITIAL_STATE: Auth = {
  email: '',
  senha: ''
};

export const fromAuthValidationSchema = object({
  email: string().email('E-mail inválido.').required(),
  senha: string().required('Campo Requerido')
});

export interface Auth {
  email: string;
  senha: string;
}

// posição do array de permissões no usuario
export enum EnumPermissao {
  'REQUISICAO' = 0,
  'COTACAO' = 1,
  'PENDENCIA' = 2,
  'PRIORIDADE' = 3,
  'SITUACAO' = 4,
  'SOLICITANTE' = 5,
  'FORNECEDOR' = 6,
  'DEPARTAMENTO' = 7
}

export const INITIAL_PERMISSAO = [
  'cru_ax_',
  'cru___f',
  '_r_____',
  'cru____',
  'cru____',
  'cru____',
  'cru____',
  'cru____'
];

export const ALL_PERMISSAO = [
  'crudaxf', // REQUISICAO
  'crudaxf', // COTACAO
  'crudaxf', // PENDENCIA
  'crudaxf', // PRIORIDADE
  'crudaxf', // SITUACAO
  'crudaxf', // SOLICITANTE
  'crudaxf', // FORNECEDOR
  'crudaxf' // DEPARTAMENTO
];

export type TPermissao = [
  'c' | '', // create
  'r' | '', // read
  'u' | '', // update
  'd' | '', // delete
  'a' | '', // aprovar
  'x' | '', // cancel
  'f' | '' // finish
];

export type TPermissaoAcion = 'c' | 'r' | 'u' | 'd' | 'a' | 'x' | 'f';
