import { ExitToApp, Check } from '@mui/icons-material';
import {
  Box,
  Modal,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  alpha
} from '@mui/material';
import { createElement, useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

interface SelectSituacaoModalProps {
  component: JSX.Element;
  action: (situacaoId: number) => void;
  situacoes: {
    id: number;
    desc: string;
    corFundo: string;
    corLetra: string;
  }[];
}

export default function SelectSituacaoModal({
  component,
  action,
  situacoes
}: SelectSituacaoModalProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = useState<number | undefined>(undefined);

  return (
    <Box>
      {createElement(component.type, {
        ...component.props,
        onClick: handleOpen
      })}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            {'Selecionar Situação'}
          </Typography>
          <Box p={2} display={'flex'} flexDirection={'column'}>
            {situacoes.map((situacao, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    value={situacao.id}
                    checked={situacao.id === selected ?? 0}
                    onChange={(e) => {
                      setSelected(Number(e.target.value));
                    }}
                    size="medium"
                  />
                }
                label={situacao.desc}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    width: '100%',
                    px: 2,
                    py: 1,
                    color: situacao.corLetra,
                    bgcolor: situacao.corFundo,
                    borderRadius: 1
                  }
                }}
              />
            ))}
          </Box>
          <Box p={2} display={'flex'} justifyContent={'space-between'}>
            <Button
              startIcon={<ExitToApp />}
              variant="contained"
              color="inherit"
              onClick={() => {
                setSelected(undefined);
                handleClose();
              }}>
              Fechar
            </Button>
            <Button
              disabled={!selected}
              variant="contained"
              startIcon={<Check />}
              color="success"
              onClick={() => {
                if (!selected) return;
                action(selected);
                handleClose();
              }}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
