import { apiSlice } from '../api/apiSlice';
import { Solicitante } from './models';

const endpointUrl = '/solicitantes';

function getSolicitantes() {
  return `${endpointUrl}`;
}

function getSolicitante({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createSolicitante(solicitante: Solicitante) {
  return { url: endpointUrl, method: 'POST', body: solicitante };
}

function deleteSolicitante({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updateSolicitante(solicitante: Solicitante) {
  const body = { ...solicitante, updated_at: solicitante.updatedAt };
  delete body.updatedAt;
  return {
    url: `${endpointUrl}/${solicitante.id}`,
    method: 'PUT',
    body
  };
}

export const solicitanteApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getSolicitantes: query<Solicitante[], void>({
      query: getSolicitantes,
      providesTags: ['Solicitantes']
    }),
    getSolicitante: query<Solicitante, { id: string }>({
      query: getSolicitante,
      providesTags: ['Solicitantes']
    }),
    createSolicitante: mutation<Solicitante, Solicitante>({
      query: createSolicitante,
      invalidatesTags: ['Solicitantes']
    }),
    deleteSolicitante: mutation<Solicitante, { id: string }>({
      query: deleteSolicitante,
      invalidatesTags: ['Solicitantes']
    }),
    updateSolicitante: mutation<Solicitante, Solicitante>({
      query: updateSolicitante,
      invalidatesTags: ['Solicitantes']
    })
  })
});

export const {
  useGetSolicitantesQuery,
  useGetSolicitanteQuery,
  useCreateSolicitanteMutation,
  useUpdateSolicitanteMutation,
  useDeleteSolicitanteMutation
} = solicitanteApiSlice;
