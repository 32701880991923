import { Edit, NavigateNext } from '@mui/icons-material';
import { Box, Paper, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton/IconButton';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';

import { Link, useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { Prioridade } from './models';
import { useGetPrioridadesQuery } from './apiSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function Prioridades() {
  const { isLoading, data } = useGetPrioridadesQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );
  const navigate = useNavigate();

  function mapDataToGridRows(data: Prioridade[]) {
    return data.map((d) => ({
      id: d.id,
      nome: d.nome,
      corFundo: d.corFundo,
      corLetra: d.corLetra,
      style: d.style
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function RenderActionsCell(params: GridRenderCellParams) {
    // const [deletePrioridade, status] = useDeletePrioridadeMutation();
    // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    // const { enqueueSnackbar } = useSnackbar();

    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //   setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    // const handleDelete = () => {
    //   deletePrioridade({ id: params.id as string });
    // };

    // useEffect(() => {
    //   if (status.isSuccess) {
    //     enqueueSnackbar('Prioridade Excluído!', { variant: 'success' });
    //   }
    //   if (status.error) {
    //     enqueueSnackbar('Error ao Excluir Prioridade', { variant: 'error' });
    //   }
    // }, [enqueueSnackbar, status.error, status.isSuccess]);

    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          component={Link}
          color="secondary"
          to={AppConfig.routes.cadPrioridadeEdit(params.id as string)}
          aria-label="edit"
          data-testid="edit-button"
          title="Editar">
          <Edit />
        </IconButton>
        {/* <IconButton
          onClick={handleClick}
          color="error"
          aria-describedby={id}
          aria-label="delete"
          data-testid="delete-button"
          title="Excluir">
          <Delete />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          <Button onClick={handleDelete} variant="text">
            Excluir?
          </Button>
        </Popover> */}
      </Box>
    );
  }

  return (
    <Inner>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs
          sx={{ py: 2 }}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography>Cadastro</Typography>
          <Typography fontWeight={'bold'}>Prioridades</Typography>
        </Breadcrumbs>
        {/* <Button
          startIcon={<Add />}
          component={Link}
          variant="contained"
          to={AppConfig.routes.cadPrioridadeInsert}>
          Inserir
        </Button> */}
      </Box>
      <Paper>
        <Box height="78vh">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: 12
              },
              fontSize: 12
            }}
            loading={isLoading}
            paginationMode="server"
            rows={data ? mapDataToGridRows(data) : []}
            columns={[
              {
                field: 'nome',
                headerName: 'Nome',
                flex: 1,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                },
                disableColumnMenu: true
              },
              {
                field: 'id',
                headerName: 'Destaque',
                width: 200,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => (
                  <Typography
                    sx={{
                      width: '100%',
                      borderRadius: '15px',
                      backgroundColor: params.row.corFundo,
                      color: params.row.corLetra,
                      px: 2,
                      py: 1,
                      textAlign: 'center'
                    }}>
                    {params.row.nome}
                  </Typography>
                )
              }
              // {
              //   field: 'id',
              //   headerName: 'Ações',
              //   width: 100,
              //   disableColumnMenu: true,
              //   sortable: false,
              //   renderCell: RenderActionsCell
              // }
            ]}
            pageSize={10}
            onRowDoubleClick={(params: GridRowParams) =>
              navigate(AppConfig.routes.cadPrioridadeEdit(params.id as string))
            }
          />
        </Box>
      </Paper>
    </Inner>
  );
}
