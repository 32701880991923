import { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';

import { useFormik } from 'formik';

import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useLoginMutation } from './authApiSlice';
import { setCredentials } from './authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_INITIAL_STATE, fromAuthValidationSchema } from './models';
import { useApiError } from '../../hooks/useApiError';
import BackImage from '../../assets/svg/login_picture.svg';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useLoadEmpresaMutation } from '../empresa/apiSlice';
import { setEmpresa } from '../empresa/storeSlice';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  minHeight: '100vh'
}));

const Login = () => {
  const [loadEmpresa] = useLoadEmpresaMutation();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [login, { isLoading, error, isError }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remember, setRemember] = useLocalStorage<boolean>('remember', true);
  const [rememberData, setRememberDate] = useLocalStorage<string>(
    'remember-data',
    ''
  );

  const ref = useRef<HTMLButtonElement>(null);

  const formik = useFormik({
    initialValues: AUTH_INITIAL_STATE,
    validationSchema: fromAuthValidationSchema,
    onSubmit: (values) => {
      if (remember) {
        setRememberDate(values.email);
      } else {
        setRememberDate('');
      }

      login({ ...values })
        .unwrap()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          const { usuario, accessToken, refreshToken } = data;
          dispatch(setCredentials({ usuario, accessToken, refreshToken }));
          // carregar dados da empresa e atualizar o state
          loadEmpresa().then(({ data }: any) => {
            console.log('🚀 ~ file: Login.tsx:71 ~ loadEmpresa ~ data:', data);
            dispatch(setEmpresa(data));
          });
          navigate('/');
        });
    }
  });

  const [errorMessage] = useApiError(isError, error);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, []);

  useEffect(() => {
    formik.setFieldValue('email', rememberData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rememberData]);

  const [showSenha, setShowSenha] = useState(false);
  const handleClickShowSenha = () => {
    setShowSenha(!showSenha);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return (
    <AuthWrapper>
      <Box display="flex" justifyContent="end">
        <Box
          flex={1}
          minHeight="100%"
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}>
          <img src={BackImage} height={'600vh'} width={'100%'} />
        </Box>
        <Grid
          container
          sx={(theme) => ({
            display: 'flex',
            maxWidth: 345,
            minHeight: '100vh',
            px: 2,
            backgroundColor: theme.palette.background.paper
          })}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={12}>
              <Grid
                container
                direction={matchDownSM ? 'column-reverse' : 'row'}
                alignItems="center"
                justifyContent="center">
                <Grid item>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}>
                    <Typography
                      color={theme.palette.secondary.main}
                      gutterBottom
                      variant={matchDownSM ? 'h3' : 'h2'}>
                      Olá, bem vindo ao Cotação Fácil
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize="16px"
                      textAlign={matchDownSM ? 'center' : 'inherit'}>
                      Entre com suas credenciais
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <form noValidate onSubmit={formik.handleSubmit}>
                <FormControl
                  fullWidth
                  spellCheck={false}
                  translate={'no'}
                  required
                  autoCorrect="off"
                  autoCapitalize="off"
                  error={Boolean(formik.touched.email && formik.errors.email)}>
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    E-Mail
                  </InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    autoComplete="off"
                    autoFocus={false}
                    value={formik.values.email}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="E-mail"
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    disabled={isLoading}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-user-login">
                      {formik.errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
                <br />
                <br />
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.senha && formik.errors.senha)}>
                  <InputLabel htmlFor="senha-login">Senha</InputLabel>
                  <OutlinedInput
                    id="senha-login"
                    autoFocus={false}
                    type={showSenha ? 'text' : 'password'}
                    value={formik.values.senha}
                    name="senha"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={isLoading}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle senha visibility"
                          onClick={handleClickShowSenha}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large">
                          {showSenha ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Senha"
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    autoComplete="off"
                  />
                  {formik.touched.senha && formik.errors.senha && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-senha-login">
                      {formik.errors.senha}
                    </FormHelperText>
                  )}
                </FormControl>

                <br />
                <br />

                <Typography color={'error'}>{errorMessage}</Typography>

                <Box sx={{ mt: 2 }}>
                  <LoadingButton
                    ref={ref}
                    disableElevation
                    disabled={isLoading}
                    loading={isLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary">
                    Entrar
                  </LoadingButton>
                </Box>

                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={remember}
                      onChange={(e) => setRemember(e.target.checked)}
                    />
                  }
                  label="Lembrar meu e-mail"
                />
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </AuthWrapper>
  );
};
export default Login;
