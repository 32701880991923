import { Paper, Box, Typography, Stack } from '@mui/material';
// import DataGridItemsRequisicaoExterno from '../edit/dataGridItemsRequisicaoExterno';
import { useEffect, useState } from 'react';
import { CotacaoFornecedor, CotacaoItem } from '../models';
import { useGetCotacaoExternoQuery } from '../apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import CountdownComp from '../../../components/CountdownComp';
import AppConfig from '../../../config/app';
import DataGridItemsRequisicaoEsterno2 from '../edit/dataGridItensRequisaoExterno';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';
import ComponentModal from '../../../components/ComponentModal';
import moment from 'moment';

export default function CotacaoValida() {
  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetCotacaoExternoQuery({
    uuid: params.uuid ?? ''
  });

  if (!isLoading && !data) {
    navigate(AppConfig.routes.cotacaoNaoLocalizadaExterno);
  }

  const [fornecedorCotacao, setFornecedorCotacao] =
    useState<CotacaoFornecedor>();
  const [itemsCotacao, setItemsCotacao] = useState<CotacaoItem[]>([]);
  const [cotacaoId, setCotacaoId] = useState<number>(0);
  const [updatedAt, setUpdatedAt] = useState<string>('');

  // calcula tempo limite
  const [limitTime, setLimitTime] = useState<number | undefined>();

  const editable = data?.situacao?.id === 100;
  const disabled = data?.fornecedores.some((item) => item.pedidoGerado);

  // previne refresh da pagina sem estar salvo
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  useEffect(() => {
    if (data) {
      setCotacaoId(data.id);
      setUpdatedAt(data.updatedAt);
      const fornecedor = data.fornecedores[0];
      setFornecedorCotacao(fornecedor);
      setItemsCotacao(data.itens);
      setLimitTime(moment(fornecedor.validade).endOf('day').valueOf());
    }
  }, [data]);

  // cancela se o fornecedor não estiver ativo
  if (fornecedorCotacao && fornecedorCotacao.isAtivo === false) {
    navigate(AppConfig.routes.cotacaoExpiradaExterno(params.uuid), {
      state: { fornecedorCotacao }
    });
  }

  // envia para cotação expirada se o tempo limite for menor que agora
  if (limitTime && limitTime < Date.now()) {
    navigate(AppConfig.routes.cotacaoExpiradaExterno(params.uuid), {
      state: { fornecedorCotacao }
    });
  }

  return (
    <Box width={'98vw'} margin={'0 auto'}>
      <Paper sx={{ p: 2, my: 1 }}>
        {/* fornecedor */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography fontSize={12} variant="h6" component="span" gutterBottom>
            Fornecedor
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={'bold'}
            variant="h6"
            component="span"
            gutterBottom>
            Esta cotação expira em:
          </Typography>
        </Box>
        {/* expiracao */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Stack gap={0}>
            <Typography
              fontSize={16}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              {fornecedorCotacao?.fornecedor.nome}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={'bold'}
              variant="h6"
              component="span"
              gutterBottom>
              CNPJ: {fornecedorCotacao?.fornecedor.cnpj} -{' '}
              {fornecedorCotacao?.fornecedor.cidade}/
              {fornecedorCotacao?.fornecedor.uf}
            </Typography>
          </Stack>

          {limitTime && limitTime > 0 && (
            <CountdownComp limitTime={limitTime} />
          )}
        </Box>
      </Paper>
      {/* itens */}
      <DataGridItemsRequisicaoEsterno2
        height="calc(100vh - 260px)"
        loading={isLoading || isFetching}
        items={itemsCotacao}
        cotacaoId={cotacaoId}
        updated_at={updatedAt}
        editable={editable}
        disabled={disabled}
        isChanged={(value) => setShowDialog(value)}
      />
      <ComponentModal
        open={showPrompt as boolean}
        close={cancelNavigation as () => void}
        title={'Existem alterações não salvas.'}
        description={'Sair sem salvar?'}
        confirmActionTitle="Não"
        confirmAction={cancelNavigation as () => void}
        degreeActionTitle="Sim"
        degreeAction={confirmNavigation as () => void}
      />
    </Box>
  );
}
