import { apiSlice } from '../api/apiSlice';
import { Produto, LastMovim } from './models';

const endpointUrlSGI = '/produtos/sgi/find';

function findProdutosSGI({
  searchFor,
  pLocal
}: {
  searchFor: string;
  pLocal: string;
}) {
  const params = {
    parametrosPesquisa: {
      searchFor,
      pesquisarNosCampos:
        'p.codigo, p.descricao, p.referencia, p.descricaoSite, p.CodigoFabricante, p.Descricao_Reduzz',
      tipoPesquisa: 0,
      pFilial: "('00')",
      pLocal,
      apenasAtivos: true,
      retirarEspacosPontuacoes: true
    }
  };

  return { url: endpointUrlSGI, method: 'POST', body: params };
}

type FindFichaSGI = Record<string, any>;

type ResultFindFichaSGI = {
  movimentacao: LastMovim[];
  qtde: number;
};

function findFichaSGI(args: FindFichaSGI) {
  const params = {
    parametrosPesquisa: {
      tipoMov: "('040')",
      pQtdeReg: 20,
      ...args
    }
  };

  return { url: '/produtos/sgi/ficha', method: 'POST', body: params };
}

// busca saldo do produto
type FindProdutoSaldoSGI = {
  codigoSgi: string;
  pLocal: string;
};

function findProdutoSaldoSGI(args: FindProdutoSaldoSGI) {
  const params = {
    parametrosPesquisa: {
      searchFor: args.codigoSgi,
      pesquisarNosCampos: 'p.codigo',
      tipoPesquisa: 0,
      pFilial: "('00')",
      pLocal: ` ('${args.pLocal}')`,
      apenasAtivos: true,
      retirarEspacosPontuacoes: true
    }
  };

  return { url: '/produtos/sgi/find', method: 'POST', body: params };
}

export const produtoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ mutation }) => ({
    findProdutosSGI: mutation<
      { produtos: Produto[]; qtde: number },
      { searchFor: string; pLocal: string }
    >({
      query: findProdutosSGI
    }),
    findFichaSGI: mutation<ResultFindFichaSGI, FindFichaSGI>({
      query: findFichaSGI
    }),
    findProdutoSaldoSGI: mutation<
      { produtos: Produto[]; qtde: number },
      FindProdutoSaldoSGI
    >({
      query: findProdutoSaldoSGI
    })
  })
});

export const {
  useFindProdutosSGIMutation,
  useFindFichaSGIMutation,
  useFindProdutoSaldoSGIMutation
} = produtoApiSlice;
