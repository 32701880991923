import { createFilterOptions } from '@mui/joy';
import FilterInterface from './filters.interface';
import { Box, Typography, Autocomplete, TextField, Stack } from '@mui/material';

export default function AutocompleteFilter({
  dataKey,
  caption,
  data,
  value,
  onChange,
  loading = false
}: FilterInterface) {
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.desc
  });

  if (loading)
    return (
      <Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ my: 0.5, backgroundColor: 'common.gray' }}>
          <Stack>
            <Typography
              fontSize={11}
              fontWeight={value?.length > 0 ? 'bold' : 'normal'}
              color={'primary'}>
              {caption}
            </Typography>
            <Typography
              fontSize={12}
              sx={{ my: 0.5, backgroundColor: 'common.gray.[10]' }}>
              Carregando...
            </Typography>
          </Stack>
        </Box>
      </Box>
    );

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Typography
          fontSize={11}
          fontWeight={value ? 'bold' : 'normal'}
          color={'primary'}>
          {caption}
        </Typography>
        {value && (
          <Typography
            fontSize={9}
            onClick={() => {
              onChange({ [dataKey]: null });
            }}
            sx={(theme) => ({
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline'
              }
            })}
            variant="overline">
            Limpar
          </Typography>
        )}
      </Box>
      {data && (
        <Autocomplete
          placeholder="Digite para buscar"
          multiple={false}
          id={'id-filter-' + dataKey}
          size={'small'}
          sx={{
            width: '100%',
            '& input': {
              fontSize: 10
            }
          }}
          value={value || ''}
          disableCloseOnSelect
          onChange={(_, newValue: string | string[] | null) => {
            if (newValue) {
              const value = newValue as unknown as {
                id: string;
                desc: string;
              };

              onChange({ [dataKey]: value.id });
            }
          }}
          options={data}
          getOptionLabel={() => {
            if (value === null) return 'Digite para buscar';
            return (
              data.find((dt) => dt.id === value)?.desc ?? 'Digite para buscar'
            );
          }}
          selectOnFocus={true}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              key={option.id}
              style={{
                fontSize: 10,
                fontWeight: selected ? 'bold' : 'inherit'
              }}>
              {option.desc}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} sx={{ fontSize: 10 }} />
          )}
          inputMode="text"
          filterOptions={filterOptions}
          isOptionEqualToValue={(option, value) => option.id === value}
        />
      )}
    </Box>
  );
}
