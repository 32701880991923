import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid';
import { Requisicao } from '../../requisicao/models';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
  alpha
} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { CustomNoTableRowsOverlay } from '../../../components/NoCustomRowOverlay';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useState } from 'react';

const columns: GridColDef[] = [
  {
    field: '__check__',
    hideable: false,
    sortable: false,
    width: 50,
    disableColumnMenu: true,
    disableExport: true,
    headerName: 'Selecionar'
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 15,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true
  },
  {
    field: 'tipoReq',
    headerName: 'Tipo',
    width: 60,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params.row.tipoReq === 'P') {
        return <QrCode2Icon titleAccess="Produto" />;
      }

      if (params.row.tipoReq === 'S') {
        return <ConstructionIcon titleAccess="Serviço" />;
      }
    }
  },
  {
    field: 'createdAt',
    headerName: 'Data',
    width: 90,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  },
  {
    field: 'codigoSgi',
    headerName: 'Código SGI',
    width: 90,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  },
  {
    field: 'descricaoProdutoServico',
    headerName: 'Produto/Serviço',
    flex: 1,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true
  },
  {
    field: 'classe',
    headerName: 'Classe',
    width: 100,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  },
  {
    field: 'solicitante',
    headerName: 'Solicitante',
    flex: 1,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  },
  {
    field: 'departamento',
    headerName: 'Destino',
    flex: 1,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  },
  {
    field: 'unidade',
    headerName: 'Und',
    width: 60,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  },
  {
    field: 'qtde',
    headerName: 'Qtde',
    align: 'center',
    width: 60,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  },
  {
    field: 'situacao',
    headerName: 'Situação',
    width: 130,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => `${value.nome}`,
    renderCell: (params) => {
      return (
        <Typography
          fontSize={10}
          sx={(theme) => ({
            color: params.row.situacao?.corLetra || '#000000',
            background: params.row.situacao?.corFundo,
            py: theme.spacing(0.5),
            px: theme.spacing(1.5),
            borderRadius: theme.shape.borderRadius
          })}>
          {params.row.situacao?.nome}
        </Typography>
      );
    }
  },
  {
    field: 'prioridade',
    headerName: 'Prioridade',
    width: 90,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => `${value.nome}`,
    sortComparator: (v1, v2) =>
      v1.prioridade.nome.localeCompare(v2.prioridade.nome),
    renderCell: (params) => {
      return (
        <Typography
          fontSize={10}
          sx={(theme) => ({
            color: params.row.prioridade?.corLetra || '#000000',
            background: params.row.prioridade?.corFundo,
            py: theme.spacing(0.5),
            px: theme.spacing(1.5),
            borderRadius: theme.shape.borderRadius
          })}>
          {params.row.prioridade?.nome}
        </Typography>
      );
    }
  },
  {
    field: 'obs',
    headerName: 'Obs',
    width: 100,
    sortable: false,
    editable: false,
    filterable: false,
    hideable: true,
    disableColumnMenu: true
  }
];

interface Props {
  setSelectedRows: (rows: Requisicao[]) => void;
  selectedRows: Requisicao[];
  data: Requisicao[];
  filter: {
    values: number[];
    dataClasses: any[];
    setFilterClasse: (filter: number[]) => void;
  };
}

export default function TableRequisicaoSelect({
  selectedRows,
  setSelectedRows,
  data,
  filter
}: Props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      'columnsRequisicaoAddCotacaoVisibles',
      {
        id: true,
        tipoReq: true,
        createdAt: true,
        solicitante: true,
        departamento: true,
        codigoSgi: true,
        descricaoProdutoServico: true,
        classe: true,
        unidade: true,
        qtde: true,
        situacao: true,
        prioridade: true,
        obs: true
      }
    );

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{ py: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={1}>
          <GridToolbarColumnsButton variant="text" />
          <GridToolbarExport variant="text" />
        </Stack>
        <Box
          sx={{
            height: '6vh',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'end'
          }}>
          <Typography variant="h6" sx={{ fontSize: 14 }}>
            Filtrar classe
          </Typography>
          <Select
            id={'id-filter-classe'}
            size={'small'}
            sx={{ fontSize: 10, backgroundColor: '#fff', minWidth: 200 }}
            multiple={true}
            role="listbox"
            value={filter?.values}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={(e) => {
              const {
                target: { value }
              } = e;
              const _filter =
                typeof value === 'string' ? value.split(',') : value;
              filter.setFilterClasse((_filter as number[]) ?? []);
            }}
            renderValue={(selected) =>
              selected
                .map((s: number) => {
                  const cl = filter.dataClasses?.find((c) => c.id === s);
                  return cl?.descricao ?? '';
                })
                .join(', ')
            }>
            {filter.dataClasses.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox
                  size="small"
                  checked={filter.values?.indexOf(option.id ?? 0) > -1}
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 11,
                    bgcolor: option?.corFundo ?? 'transparent',
                    color: option?.corLetra ?? 'inherit',
                    padding: 1,
                    borderRadius: '15px'
                  }}
                  primary={option.descricao}
                />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </GridToolbarContainer>
    );
  }

  function CustomPagination() {
    return (
      <Box
        minWidth={'96%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
        mx={2}
        px={0}>
        {/* <Typography>{selectedRows?.length} Registro(s)</Typography> */}

        <Typography fontSize={12}>
          Selecionado(s): {selectedRows.length}
        </Typography>
        <Typography fontSize={12}>Qtde: {data.length}</Typography>
      </Box>
    );
  }

  function mapDataToGridRows(data: Requisicao[]) {
    return data.map((d) => ({
      id: d.id,
      tipoReq: d.tipoReq,
      createdAt: d.createdAt
        ? new Date(d.createdAt).toLocaleDateString('pt-BR')
        : '',
      solicitante: d.solicitante?.nome,
      departamento: d.departamento?.nome,
      codigoSgi: d.produtoServico?.codigoSgi,
      descricaoProdutoServico: d.descricaoProdutoServico,
      classe: d.produtoServico?.classe?.descricao,
      unidade: d.unidade,
      qtde: d.qtde,
      situacao: d.situacao,
      prioridade: d.prioridade,
      obs: d.obs
    }));
  }

  function seletedModelChange(ids: number[]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedRowsData = data.filter((row: any) => {
      if (row.id) return ids.includes(row.id);
      return false;
    });

    setSelectedRows(selectedRowsData);
  }

  return (
    <Box sx={{ height: '63vh', width: '100%' }}>
      <DataGrid
        sx={(style) => ({
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            fontSize: 10,
            backgroundColor: style.palette.secondary.main,
            color: style.palette.secondary.contrastText
          },
          '& .MuiDataGrid-columnSeparator': {
            color: style.palette.secondary.contrastText
          },
          fontSize: 11,
          overflow: 'hidden',
          '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
            color: style.palette.secondary.contrastText,
            borderRadius: 0
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
          '& .super-app-theme--true': {
            bgcolor: (theme) => alpha(theme.palette.success.light, 0.3),
            '&:hover': {
              bgcolor: (theme) => theme.palette.success.main
            }
          },
          '& .super-app-theme--false': {
            bgcolor: (theme) => alpha(theme.palette.warning.light, 0.3),
            '&:hover': {
              bgcolor: (theme) => theme.palette.warning.main
            }
          }
        })}
        localeText={{
          toolbarColumnsLabel: 'Colunas',
          toolbarColumns: '',
          toolbarExport: '',
          toolbarExportLabel: 'Exportar',
          toolbarExportCSV: 'Exportar para CSV',
          toolbarExportPrint: 'Imprimir',
          detailPanelToggle: 'Expandir',
          columnsPanelHideAllButton: 'Ocultar tudo',
          columnsPanelShowAllButton: 'Mostrar tudo',
          columnsPanelTextFieldLabel: 'Localizar coluna',
          columnsPanelTextFieldPlaceholder: 'Título da coluna'
        }}
        rows={mapDataToGridRows(data)}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(ids) => seletedModelChange(ids.map(Number))}
        selectionModel={selectedRows.map((row) => row.id) as GridSelectionModel}
        density="compact"
        paginationMode="server"
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
          // LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoTableRowsOverlay
        }}
        hideFooterSelectedRowCount
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },

            csvOptions: {
              delimiter: ';',
              utf8WithBom: true,
              fileName: 'sistema-cotação-add-requisicao'
            }
          },
          footer: {
            sx: {
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              '& .MuiPaginationItem-root': {
                fontSize: 12
              },
              '& *': {
                color: 'secondary.contrastText'
              }
            }
          }
        }}
      />
    </Box>
  );
}
