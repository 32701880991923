import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { Fornecedor, FornecedorSGI } from '../models';
import { DataGrid } from '@mui/x-data-grid';
import { useFindFornecedoresSGIMutation } from '../apiSlice';
import { Add } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1,
  height: '87vh'
};

interface Props {
  disabled?: boolean;
  action?: (fornecedor: Fornecedor) => void;
}

export default function SearchFornecedorModal({
  action,
  disabled = false
}: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [searchFor, setSearchFor] = useState<string>('');
  const [data, setData] = useState<FornecedorSGI[]>([]);
  const [findFornecedoresSGI, status] = useFindFornecedoresSGIMutation();

  useEffect(() => {
    ref.current?.focus();
    ref.current?.select();
    // ref.current?.value = '123' ?? '';
  }, [open]);

  async function findFornecedores() {
    const { fornecedores } = await findFornecedoresSGI({
      parametrosPesquisa: {
        searchFor: searchFor
      }
    }).unwrap();
    if (fornecedores) {
      setData(fornecedores);
    }
  }

  function mapFornecedor(fornecedorSGI: FornecedorSGI[]): Fornecedor[] {
    return fornecedorSGI.map((f: FornecedorSGI) => ({
      id: f.codigopalm ?? undefined,
      tipoPessoa: f.tipopessoa, // 'F' | 'J'
      nome: f.nome ?? '',
      apelido: f.apelido ?? '',
      fone: f.fone1 ?? '',
      fone2: f.fone2 ?? '',
      cel: f.cel ?? '',
      email: f.email ?? '',
      cnpj: f.cnpj ?? '',
      cidade: f.cidade ?? '',
      uf: f.uf ?? '',
      isActive: true,
      isVisible: true,
      createdAt: new Date(),
      updatedAt: new Date()
    }));
  }

  return (
    <Box>
      <Button
        disabled={disabled}
        startIcon={<Add />}
        size="small"
        variant="contained"
        color="primary"
        onClick={handleOpen}>
        Fornecedores
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            Consulta Fornecedores
          </Typography>
          <Box p={2}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                    zIndex: 10
                  })}
                />
              </SearchIconWrapper>
              <StyledInputBase
                inputRef={ref}
                autoFocus={true}
                placeholder="Pesquisar fornecedor…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => setSearchFor(e.target.value)}
                value={searchFor}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && searchFor.length > 2) {
                    findFornecedores();
                  }
                }}
              />
            </Search>
            <Box height={'58vh'}>
              {/* <Typography
                variant="body2"
                sx={{ ml: 1, fontSize: 10, color: '#ff0000' }}>
                (*) Para selecionar um fornecedor, clique duas vezes sobre o
                registro.
              </Typography> */}
              <DataGrid
                sx={{
                  '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle':
                    {
                      fontWeight: 'bold',
                      fontSize: 10,
                      color: '#ffffff',
                      backgroundColor: 'primary.main'
                    },
                  fontSize: 10,
                  backgroundColor: '#ffffff',
                  height: '66vh',
                  overflow: 'hidden'
                }}
                loading={status.isLoading}
                // paginationMode="server"
                rows={data ? mapFornecedor(data) : []}
                density="compact"
                columns={[
                  {
                    field: 'nome',
                    headerName: 'Nome',
                    flex: 1,
                    sortable: true,
                    editable: false,
                    filterable: false,
                    hideable: false,
                    disableColumnMenu: true
                  },
                  {
                    field: 'apelido',
                    headerName: 'Fantasia',
                    flex: 1,
                    sortable: true,
                    editable: false,
                    filterable: false,
                    hideable: false,
                    disableColumnMenu: true
                  },
                  {
                    field: 'cnpj',
                    headerName: 'CNPJ',
                    width: 150,
                    sortable: true,
                    editable: false,
                    filterable: false,
                    hideable: false,
                    disableColumnMenu: true
                  },
                  {
                    field: 'cidade',
                    headerName: 'Cidade',
                    width: 120,
                    sortable: true,
                    editable: false,
                    filterable: false,
                    hideable: false,
                    disableColumnMenu: true
                  },
                  {
                    field: 'uf',
                    headerName: 'UF',
                    width: 50,
                    sortable: true,
                    editable: false,
                    filterable: false,
                    hideable: false,
                    disableColumnMenu: true
                  }
                ]}
                pageSize={6}
                onRowDoubleClick={(e) => {
                  if (action) {
                    action(e.row as Fornecedor);
                  }
                  handleClose();
                }}
                onCellKeyDown={({ row }, { key }) => {
                  if (key === 'Enter' && action) {
                    action(row as Fornecedor);
                    handleClose();
                  }
                }}
                paginationMode="server"
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
                components={{
                  Footer: () => (
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: theme.palette.primary.main,
                        padding: theme.spacing(1)
                      })}>
                      <Typography
                        variant="body2"
                        sx={(theme) => ({
                          ml: 1,
                          fontSize: 10,
                          color: theme.palette.primary.contrastText
                        })}>
                        (*) Para selecionar um fornecedor, clique duas vezes
                        sobre o registro.
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={(theme) => ({
                          ml: 1,
                          fontSize: 10,
                          color: theme.palette.primary.contrastText
                        })}>
                        Qtde: {data.length}
                      </Typography>
                    </Box>
                  )
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  },
  marginBottom: theme.spacing(2)
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden'
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch'
    //   }
    // }
  },
  variant: 'outlined'
}));
