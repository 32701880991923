import React from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';
import { Requisicao } from '../../requisicao/models';
import ConstructionIcon from '@mui/icons-material/Construction';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { Box, Tooltip, Typography } from '@mui/material';
import GridActionConfirmModal from '../../../components/gridActionConfirmModal';
import { CustomNoTableRowsOverlay } from '../../../components/NoCustomRowOverlay';
import ChangeQtdeModal from './changeQtdeModal';
import { EditRounded } from '@mui/icons-material';
import ChipPedido from './chipPedido';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useCotacaoItemQtdeUpdateMutation } from '../apiSlice';
import { useSnackbar } from 'notistack';

interface Props {
  data?: Partial<Requisicao>[];
  actionDelete: (id: string | number, updatedAt: string) => void;
  disabled?: boolean;
  toolbarComponents: React.ReactNode;
  columns?: any[];
  cotacao?: {
    cotacaoId: number;
    updated_at: string;
  };
}

export default function TableRequisicao({
  data,
  actionDelete,
  disabled,
  toolbarComponents,
  columns,
  cotacao
}: Props) {
  // console.log({ data });
  const { enqueueSnackbar } = useSnackbar();
  const [cotacaoItemQtdeUpdate] = useCotacaoItemQtdeUpdateMutation();
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          py: 1,
          gap: 2,
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Box sx={{ display: 'flex' }}>
          {toolbarComponents
            ? React.cloneElement(toolbarComponents as React.ReactElement)
            : null}
          <GridToolbarColumnsButton variant="text" />
          <IconButton onClick={handleExport}>
            <GetApp color="primary" />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2">
            {data?.length ?? 0} registro(s)
          </Typography>
        </Box>
      </GridToolbarContainer>
    );
  };

  const mapToExcel = ((data as any[]) ?? []).map((d) => {
    return {
      Req: d.id,
      'Tipo de requisição': d.tipoReq === 'P' ? 'Produto' : 'Serviço',
      'Data de criação': new Date(d.createdAt).toLocaleDateString('pt-BR'),
      'Código SGI': d.codigoSgi,
      'Descrição do produto/serviço': d.descricaoProdutoServico,
      'Unidade de medida': d.unidade,
      Quantidade: d.qtde,
      Observação: d.obs
    };
  });

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'Cotacao-Requisicoes.xlsx');
  }

  return (
    <DataGrid
      sx={(style) => ({
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          fontSize: 10,
          backgroundColor: style.palette.secondary.main,
          color: style.palette.secondary.contrastText
        },
        '& .MuiDataGrid-columnSeparator': {
          color: style.palette.secondary.contrastText
        },
        fontSize: 10,
        overflow: 'hidden',
        '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
          color: style.palette.secondary.contrastText,
          borderRadius: 0
        },
        '& .MuiDataGrid-columnHeaders': {
          borderRadius: 0
        },
        '& .super-app-theme--pedido': {
          color: (theme) => theme.palette.grey[500],
          '&:hover': {
            color: (theme) => theme.palette.grey[800]
          }
        },
        '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
          opacity: 1,
          color: style.palette.secondary.contrastText
        }
      })}
      getRowClassName={(params) =>
        params.row.pedido ? `super-app-theme--pedido` : ''
      }
      disableColumnFilter
      disableColumnMenu
      disableDensitySelector
      rows={data ?? []}
      columns={
        columns ?? [
          {
            field: 'id',
            headerName: 'ID',
            width: 15,
            sortable: true,
            editable: false,
            filterable: false,
            hideable: true,
            disableColumnMenu: true,
            renderCell: (params) => {
              return <Typography fontSize={10}>{params.row.id}</Typography>;
            }
          },
          {
            field: 'tipoReq',
            headerName: 'Tipo',
            width: 60,
            sortable: true,
            editable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              if (params.row.tipoReq === 'P') {
                return <QrCode2Icon titleAccess="Produto" />;
              }

              if (params.row.tipoReq === 'S') {
                return <ConstructionIcon titleAccess="Serviço" />;
              }
            }
          },
          {
            field: 'createdAt',
            headerName: 'Data',
            width: 100,
            sortable: true,
            editable: false,
            filterable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) =>
              new Date(value).toLocaleDateString('pt-BR')
          },
          {
            field: 'codigoSgi',
            headerName: 'Cód SGI',
            width: 75,
            sortable: true,
            editable: false,
            filterable: false,
            disableColumnMenu: true
          },
          {
            field: 'pedido',
            headerName: 'Pedido',
            align: 'center',
            width: 100,
            sortable: true,
            editable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              if (params.value === null) return <>-</>;
              else return <ChipPedido pedidoId={String(params.value)} />;
            }
          },
          {
            field: 'descricaoProdutoServico',
            headerName: 'Produto/Serviço',
            flex: 1,
            sortable: true,
            editable: false,
            filterable: false,
            hideable: true,
            disableColumnMenu: true
            // renderCell(params) {
            //   const { row, value } = params;
            //   return (
            //     <Tooltip title={value} arrow>
            //       <Box display={'flex'} alignItems={'center'} gap={1}>
            //         {row.pedido && <ChipPedido pedidoId={String(row.pedido)} />}
            //         <Typography fontSize={12}>{value}</Typography>
            //       </Box>
            //     </Tooltip>
            //   );
            // }
          },
          // {
          //   field: 'solicitante',
          //   headerName: 'Solicitante',
          //   flex: 1,
          //   sortable: true,
          //   editable: false,
          //   filterable: false,
          //   hideable: false,
          //   disableColumnMenu: true
          // },
          // {
          //   field: 'departamento',
          //   headerName: 'Destino',
          //   flex: 1,
          //   sortable: true,
          //   editable: false,
          //   filterable: false,
          //   hideable: false,
          //   disableColumnMenu: true
          // },
          {
            field: 'unidade',
            headerName: 'Und',
            width: 60,
            sortable: true,
            editable: false,
            filterable: false,
            disableColumnMenu: true
          },
          {
            field: 'qtde',
            headerName: 'Qtde',
            align: 'center',
            width: 60,
            sortable: true,
            editable: false,
            filterable: false,
            disableColumnMenu: true
          },
          // {
          //   field: 'situacao',
          //   headerName: 'Situação',
          //   width: 130,
          //   sortable: true,
          //   editable: false,
          //   filterable: false,
          //   hideable: false,
          //   disableColumnMenu: true,
          //   valueFormatter: ({ value }) => `${value.nome}`,
          //   renderCell: (params) => {
          //     return (
          //       <Typography
          //         fontSize={10}
          //         sx={(theme) => ({
          //           color: params.row.situacao?.corLetra || '#000000',
          //           background: params.row.situacao?.corFundo,
          //           py: theme.spacing(0.5),
          //           px: theme.spacing(1.5),
          //           borderRadius: theme.shape.borderRadius
          //         })}>
          //         {params.row.situacao?.nome}
          //       </Typography>
          //     );
          //   }
          // },
          // {
          //   field: 'prioridade',
          //   headerName: 'Prioridade',
          //   width: 90,
          //   sortable: true,
          //   editable: false,
          //   filterable: false,
          //   hideable: false,
          //   disableColumnMenu: true,
          //   valueFormatter: ({ value }) => `${value.nome}`,
          //   sortComparator: (v1, v2) =>
          //     v1.prioridade?.nome.localeCompare(v2.prioridade?.nome),
          //   renderCell: (params) => {
          //     return (
          //       <Typography
          //         fontSize={10}
          //         sx={(theme) => ({
          //           color: params.row.prioridade?.corLetra || '#000000',
          //           background: params.row.prioridade?.corFundo,
          //           py: theme.spacing(0.5),
          //           px: theme.spacing(1.5),
          //           borderRadius: theme.shape.borderRadius
          //         })}>
          //         {params.row?.prioridade?.nome}
          //       </Typography>
          //     );
          //   }
          // },

          {
            field: 'obs',
            headerName: 'Obs',
            width: 100,
            sortable: true,
            editable: false,
            filterable: false,
            disableColumnMenu: true
          },
          {
            field: 'actions',
            headerName: 'Ações',
            width: 90,
            disableColumnMenu: true,
            sortable: true,
            hideable: false,
            align: 'center',
            renderCell: function (params: GridRenderCellParams) {
              if (params?.row?.pedido) return <></>;
              return [
                <ChangeQtdeModal
                  title="Alterar quantidade"
                  description="Informe a nova quantidade"
                  component={
                    <Tooltip title="Alterar quantidade" arrow>
                      <GridActionsCellItem
                        icon={<EditRounded />}
                        label={'Alterar quantidade'}
                      />
                    </Tooltip>
                  }
                  value={params.row.qtde ?? 0}
                  action={(newQtde: number) => {
                    if (!cotacao) return;
                    console.log('row', params.row);
                    cotacaoItemQtdeUpdate({
                      cotacaoId: Number(cotacao.cotacaoId),
                      updated_at: cotacao.updated_at || '',
                      requisicao: {
                        requisicaoId: Number(params.row.id),
                        updated_at: params.row.updatedAt?.toString() || '',
                        // updated_at: cotacao.updated_at || '',
                        qtde: newQtde
                      }
                    })
                      .unwrap()
                      .then(() => {
                        //
                        enqueueSnackbar('Quantidade alterada com sucesso', {
                          variant: 'success'
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                        enqueueSnackbar(err.data.message, {
                          variant: 'error'
                        });
                      });
                  }}
                  key={0}
                />,
                <GridActionConfirmModal
                  icon={
                    <Tooltip title="Excluir requisição" arrow>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label="Exclusão de requisição"
                  descriptions={
                    <>
                      <Typography variant="body2" gutterBottom>
                        Excluir a requisição?
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {params.row?.descricaoProdutoServico}
                      </Typography>
                    </>
                  }
                  className="textError"
                  action={() => {
                    if (!data) return;

                    const row = data.filter((row) => {
                      return row.id === params.row.id;
                    })[0];
                    const cotacaoId = (row as any)?.id;
                    const updated_at = (row as any)?.updatedAt;
                    cotacaoId &&
                      updated_at &&
                      actionDelete(cotacaoId, updated_at);
                  }}
                  key={1}
                />
              ];
            }
          }
        ]
      }
      // pageSize={10}
      // rowsPerPageOptions={[10]}
      density="compact"
      hideFooter
      components={{
        Toolbar: CustomToolbar,
        NoRowsOverlay: CustomNoTableRowsOverlay
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          showColumnsButton: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: {
            delimiter: ';',
            utf8WithBom: true,
            fileName: 'sistema-cotação'
          }
        },
        footer: {
          sx: {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            '& .MuiPaginationItem-root': {
              fontSize: 12
            },
            '& *': {
              color: 'secondary.contrastText'
            }
          }
        }
      }}
      localeText={{
        toolbarColumnsLabel: 'Colunas',
        toolbarColumns: '',
        toolbarExport: '',
        toolbarExportLabel: 'Exportar',
        toolbarExportCSV: 'Exportar para CSV',
        toolbarExportPrint: 'Imprimir',
        detailPanelToggle: 'Expandir',
        columnsPanelHideAllButton: 'Ocultar tudo',
        columnsPanelShowAllButton: 'Mostrar tudo',
        columnsPanelTextFieldLabel: 'Localizar coluna',
        columnsPanelTextFieldPlaceholder: 'Título da coluna'
      }}
    />
  );
}
