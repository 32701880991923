import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from './authSlice';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const Autenticated = useSelector(selectIsAuthenticated);
  const location = useLocation();
  if (Autenticated) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};
export default RequireAuth;
