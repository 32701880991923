/* eslint-disable @typescript-eslint/no-empty-function */
import crypto from 'crypto-js';

const AES = process.env.REACT_APP_AES || 'GRGO@APP@DEVLOPMENT@FULL';

const encryptor = (value: string) => crypto.AES.encrypt(value, AES).toString();

const decryptor = (value: string) =>
  crypto.AES.decrypt(value, AES).toString(crypto.enc.Utf8);

export { encryptor, decryptor };
