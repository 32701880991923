/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  Column,
  RenderCellProps,
  RenderHeaderCellProps
} from 'react-data-grid';
import { SelectColumn, textEditor } from 'react-data-grid';
import { TRow } from './rows';
import {
  dateEditor,
  renderCurrency,
  renderDate,
  renderNumber,
  renderTextCenter
} from '../../common/gridColumnsRendersEditors';

const columns: readonly Column<TRow>[] = [
  SelectColumn,
  {
    key: 'requisicaoId',
    name: 'ID',
    width: 35,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <center style={{ fontSize: '0.65em' }}>{props.column.name}</center>
    // ),
    renderCell: (props: RenderCellProps<TRow, any>) =>
      renderTextCenter(props as any)
  },
  {
    key: 'codigoSgi',
    name: 'Código SGI',
    width: 75,
    maxWidth: 100,
    minWidth: 75,
    headerCellClass: 'header-cell-size'
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <center style={{ fontSize: '0.65em' }}>{props.column.name}</center>
    // )
  },
  {
    key: 'descricao',
    name: 'Descrição',
    minWidth: 100,
    maxWidth: 900,
    width: 250,
    resizable: true,
    renderCell({ row }) {
      return (
        <div
          style={{
            fontWeight: row.situacao.id !== 210 ? 'bold' : 'normal'
          }}>
          {row.descricao}
        </div>
      );
    },
    headerCellClass: 'header-cell-size'
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // )
  },
  // {
  //   key: 'situacao',
  //   name: 'Situação',
  //   maxWidth: 100,
  //   minWidth: 100,
  //   renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
  //     <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
  //   ),
  //   renderCell({ row }) {
  //     return (
  //       <div
  //         style={{
  //           backgroundColor: row.situacao.corFundo,
  //           color: row.situacao.corLetra,
  //           textAlign: 'center',
  //           fontWeight: row.situacao.id !== 210 ? 'bold' : 'normal'
  //         }}>
  //         {row.situacao.nome}
  //       </div>
  //     );
  //   }
  // },
  {
    key: 'seuCodigo',
    name: 'Código Fornecedor',
    minWidth: 110,
    maxWidth: 100,
    resizable: true,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // ),
    renderEditCell: (props) => textEditor(props)
  },
  {
    key: 'qtde',
    name: 'Qtde',
    minWidth: 60,
    maxWidth: 100,
    resizable: true,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // ),
    // renderEditCell: (props) => numberEditor(props),
    // editorOptions: {
    //   displayCellContent: false,
    //   commitOnOutsideClick: true
    // },
    renderCell: (props) => renderNumber(props as any)
  },
  // {
  //   key: 'icms',
  //   name: 'ICMS(%)',
  //   width: 60,
  //   renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
  //     <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
  //   ),

  //   renderCell: (props) => renderNumber(props as any)
  // },
  // {
  //   key: 'ipi',
  //   name: 'IPI(%)',
  //   width: 60,
  //   renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
  //     <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
  //   ),
  //   renderCell: (props) => renderNumber(props as any)
  // },
  // {
  //   key: 'frete',
  //   name: 'Frete(%)',
  //   width: 60,
  //   renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
  //     <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
  //   ),
  //   renderCell: (props) => renderNumber(props as any)
  // },
  // {
  //   key: 'outrasDespesas',
  //   name: 'Outras(%)',
  //   width: 70,
  //   renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
  //     <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
  //   ),
  //   renderCell: (props) => renderNumber(props as any)
  // },
  {
    key: 'preco',
    name: 'Preço',
    width: 100,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // ),
    renderCell: (props) => renderCurrency(props as any, '#c0c0c0')
  },
  {
    key: 'precoComImposto',
    name: 'Preço + Impostos',
    width: 100,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // ),
    renderCell: (props) => renderCurrency(props as any, '#ffd5b8')
  },
  {
    key: 'total',
    name: 'Total(R$)',
    width: 100,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // ),
    renderCell: (props) => renderCurrency(props as any, '#a3d6ff')
  },
  {
    key: 'previsao',
    name: 'Previsão',
    resizable: false,
    width: 150,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // ),
    renderEditCell: (props) => dateEditor(props as any),
    renderCell: (props) => renderDate(props as any)
  },
  {
    key: 'obs',
    name: 'Observações',
    resizable: true,
    minWidth: 100,
    headerCellClass: 'header-cell-size',
    // renderHeaderCell: (props: RenderHeaderCellProps<TRow, unknown>) => (
    //   <span style={{ fontSize: '0.65em' }}>{props.column.name}</span>
    // ),
    renderEditCell: (props) => textEditor(props)
  }
];

export default columns;
